import Authenticator from './Authenticator.js';
import { Orders, Files, Files2, Signatures, Submissions, AdminSubmissions, AdminOrders } from './apiconnect.js';

const authX = new Authenticator(container,'authenticator');

const ACCOUNT = {};

function pageAccount(container) {
    console.log('pageAccount INIT', container);

    return new Promise( async (resolve) => {
        container.replaceChildren(); //Clear current view

        let msgBox = renderMessageBox({
            message:'Loading...',
        });
        container.appendChild(msgBox);

        // //Authentication listeners
        container.addEventListener('authEvent', async (e) => {
            console.log('authEvent', e.detail);
        
            switch(e.detail.name) {
            case 'signedIn':
                container.replaceChildren(await renderSignedInPages());
            break;
        
            case 'signedOut':
                container.replaceChildren(renderSignedOutPages());
            break;

            case 'pauseMessage':
                const pauseId = 'pauseMsg';
                const pauseElement = document.getElementById(pauseId);

                if(e.detail.hasOwnProperty('message')) {
                    //Add element if not already exists
                    if(!container.contains(pauseElement)) {
                        let pauseMsg = renderMessageBox({
                            id: pauseId,
                            message:e.detail.message,
                        });
                        container.prepend(pauseMsg);
                    }
                }
                else
                {
                   if(container.contains(pauseElement)) pauseElement.remove();
                }
            break;
        
            default:
                console.log('Unhandled event: ', e.detail.name);
            }
        });

        const currentUser = await authX.getCurrentUser();

        if(!currentUser) {
            console.log('User not signed in')
            container.replaceChildren(renderSignedOutPages());
        }
        else {
            console.log('User signed in')
            container.replaceChildren(await renderSignedInPages());
        }
    
        //Remove msgBox if still exists on DOM
        if(container.contains(msgBox)) container.removeChild(msgBox);
    
        msgBox = null;

        resolve();
    })

    function renderMessageBox(o) {
        const root = document.createElement('div');
        if(o.hasOwnProperty('id')) root.id = o.id; //To enable removal
        root.classList.add('messageBoxContainer');
        root.innerHTML = `${o.message}`;
        return root;
    }

    function renderSignedOutPages()  {
        const root = document.createElement('div');
        root.classList.add('accountContainer');
        root.appendChild(authX.render('signIn'));
        return root;
    }

    function renderOrders(data, context) {
        //console.log('renderOrders - init', context);
        //console.log(data);

        //Sort orders on createdAt, descending order
        data.sort(function (a, b) {
            return b.createdAt.localeCompare(a.createdAt);
        });

        const root = document.createElement('div');
        //root.id = 'orders';
        root.classList.add('orders');
        root.innerHTML = `
        <div id='ordersContainer'>
            <div id='orders' class='orderSection'>
                <h2>Orders</h2>
                <div class="menuHeader menuRow menu smaller">
                    <div class="width24">ID</div>
                    <div class="width8">OrgNr</div>
                    <div class="width16">Kontakt</div>
                    <div class="width8">Skapad</div>
                    <div class="width8">Status</div>
                </div>
            </div>
        </div>
        <div id='actionsContainer'></div>`;

        for(const order of data) {
            //Prepare createdAt date
            const d = new Date(order.createdAt);
            const options = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            };
            //console.log(d.toLocaleDateString('se-SE', options));

            //Get tags if available (orgId, contactName)
            const tags = {};
            if(order.hasOwnProperty('tags')) {
                for (const [key, value] of order.tags) {
                    if(key === 'orgId') tags.orgId = decodeURIComponent(value);
                    if(key === 'contactName') tags.contactName = decodeURIComponent(value);
                }
            }
            
            const row = document.createElement('div');
            row.id = `order|${order.sk}`;
            row.classList.add('menuItem', 'menuRow', 'menu', 'smaller');
            row.innerHTML = `
                <div class="width24">${order.sk}</div>
                <div class="width8">${tags.orgId}</div>
                <div class="width16">${tags.contactName}</div>
                <div class="width8">${d.toLocaleDateString('se-SE', options)}</div>
                <div class="width8">${order.status}</div>
            `;
            row.addEventListener('click', ( async (e) => {
                e.preventDefault();
                //console.log('renderOrders - CLICK-> Get order-> ', e.currentTarget.id);

                //Loading message
                const accountContainer = document.getElementById('accountContainer');
                const referenceNode = document.getElementById('accountMain'); 
                let msgBox = renderMessageBox({
                    message:'Loading...',
                });

                try {
                    accountContainer.insertBefore(msgBox, referenceNode);
                    referenceNode.replaceChildren();

                    const id = e.currentTarget.id.split('|')[1];
                    //console.log('id', id);

                    const accessToken = await authX.getTokens();
                    //console.log('accessToken 333', accessToken);

                    const userAttributes = await authX.getUserAttributes();
                    //console.log('userAttributes', userAttributes);
                
                    const order = await Orders({
                        accessToken:accessToken,
                        sub:userAttributes.sub,
                        id:id,
                    })
                    console.log('renderOrders - order', order);
        
                    if(order) {
                        //Success
                        //console.log('renderOrders - order success', order);
                        
                        //render...
                        //renderOrder(order);
                        referenceNode.replaceChildren(renderOrder(order));
                    }
                    else {
                        //Fail 
                        console.log('renderOrders - No orders found - Error?', order);
                        throw new Error(`Autentieringsfel`);
                    }
                } catch (error) {
                    console.log('renderOrders - click event error, Unhandled', error);
                    alert('Unhandled error on ORDERS');
                }

                //Remove msgBox if still exists on DOM
                if(accountContainer.contains(msgBox)) accountContainer.removeChild(msgBox);
                msgBox = null;
            }))
            //root.appendChild(row);
            root.querySelector('#orders').appendChild(row);
        }

        //--- Actions ---
        //Only include COMPLETED for now, possibly pending -> disabled
        //Almost form
        
        //Check if filter -> status exists
        let slider1Value = 1; //No filter means it IS included
        if(context !== undefined && context.hasOwnProperty('statuses')) {
            if(!context.statuses.includes('COMPLETED')) slider1Value = 0;
        }
        
        //Actions container
        const elemActions = document.createElement('div');
        elemActions.classList.add('orderSection');
        elemActions.setAttribute('style', 'max-width:18em');
        elemActions.innerHTML = `<h3>Actions</h3>
        <div id='actionContainer1' class='sliderContainer'></div>`;

        //actionContainer1 Label
        const statusSliderLabel = document.createElement('label');
        statusSliderLabel.id = "sliderLabel";
        statusSliderLabel.for = "slider";
        statusSliderLabel.textContent = `Inkludera COMPLETED`;
        elemActions.querySelector('#actionContainer1').appendChild(statusSliderLabel);

        //actionContainer1 Input / range
        const statusSlider = document.createElement('div');
        statusSlider.classList.add('slider');
        const statusSliderInput = document.createElement('input');
        statusSliderInput.classList.add('sliderThumb');
        statusSliderInput.id = "slider1";
        statusSliderInput.type = 'range';
        statusSliderInput.min = 0;
        statusSliderInput.max = 1;
        statusSliderInput.value = slider1Value;
        statusSliderInput.step = 1;
        statusSliderInput.addEventListener('change', async e => {
            //Action event (Update DB)
            e.preventDefault;
            const slider = e.currentTarget;

            //Disable
            slider.disabled = true;
            statusSliderLabel.textContent = `Please wait...`;
            //Update
            try {
                //Same as getOrders...
               console.log('renderSignedInPages - Fetching orders....');
               const accessToken = await authX.getTokens();
               const arrStatuses = [ 'PENDING' ]; //Default

               if(slider.value === '1') arrStatuses.push('COMPLETED');
   
               const orders = await Orders({
                   accessToken:accessToken,
                   filter: {
                       statuses: arrStatuses,
                   },
               })
               //console.log('renderSignedInPages - orders', orders);
   
               if(orders) {
                   //Success
                   accountMain.replaceChildren(renderOrders(orders, {
                       statuses: arrStatuses,
                   }));
                   return orders;
               }
               else {
                   //Fail. Currently never landing here
                   console.log('renderSignedInPages - Error zzzz', orders); //false
                   throw new Error(`Autentieringsfel.`); //Bubble error handling
               }
            } catch (error) {
                console.log('pa - Error order', error);
                statusSliderLabel.textContent = `ERROR! Ändra status (?)`;
            }
            //Re-enable
            slider.disabled = false;
        })
        statusSlider.appendChild(statusSliderInput);
        elemActions.querySelector('#actionContainer1').appendChild(statusSlider);
        root.querySelector('#actionsContainer').appendChild(elemActions);

        return root;
    }

    function renderOrder(data) {
        console.log('renderOrder - init');
        //console.log(data);

        const order = data[0];
        console.log('renderOrder', order);

        const d = new Date(order.createdAt);
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        };
        //console.log(d.toLocaleDateString('se-SE', options));

        //Get tags if available (orgId, contactName)
        const tags = {};
        if(order.hasOwnProperty('tags')) {
            for (const [key, value] of order.tags) {
                if(key === 'orgId') tags.orgId = decodeURIComponent(value);
                if(key === 'contactName') tags.contactName = decodeURIComponent(value);
            }
        }
        console.log('tags', tags);

        const root = document.createElement('div');
        root.id = 'orderContainer';
        root.classList.add('order')
        root.innerHTML = `<h2>Order</h2>`;

        const elemMain = document.createElement('div');
        elemMain.innerHTML = `
            <ul class="orderList">
                <li><span>Order Id:</span><span>${order.sk}</span></li>
                <li><span>Order skapad:</span><span>${d.toLocaleDateString('se-SE', options)}</span></li>
                <li><span>Org-nummer:</span><span>${tags.orgId}</span></li>
                <li><span>Kontaktperson:</span><span>${tags.contactName}</span></li>
                <li><span>Order status:</span><span id='orderListStatus'>${order.status}</span></li>
            </ul>
        `;
        root.appendChild(elemMain);

        const elemDocuments = document.createElement('div');
        elemDocuments.classList.add('orderSection');
        elemDocuments.innerHTML = `
            <h3>Documents</h3>
            <div class="menuHeader menuRow menu smaller">
                <div class="width12">Filnamn</div>
                <div class="width8">Skapad</div>
                <div class="width12">Template</div>
                <div class="width12">Signatur</div>
            </div>
        `;

        for(const doc of order.documents) {
            console.log('doc', doc);

            const d = new Date(order.createdAt);
            const options = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              };
            //console.log(d.toLocaleDateString('se-SE', options));

            const row = document.createElement('div');
            row.id = `document|${doc.ref}`;
            row.classList.add('menuItem', 'menuRow', 'menu', 'smaller');

            const col1 = document.createElement('div');
            col1.classList.add('width12', 'textHover');
            col1.innerHTML = `${doc.pdf.filename}`;
            col1.addEventListener('click', async (e) => {
                e.preventDefault();

                //console.log('Click-> Download PDF', e.currentTarget);
                const currentTarget = e.currentTarget;
                const id = currentTarget.textContent; //Filename
                const text = currentTarget.textContent; //Filename
                const pauseText = `Please wait...`;
                //console.log('text', text);

                try {
                    //Prevent dbl-click
                    if(currentTarget.innerHTML === pauseText) {
                        //Process is already running. Avoiid dbl-click
                        //console.log('Returning...');
                        return;
                    }

                    currentTarget.textContent = pauseText;

                    const accessToken = await authX.getTokens();
                    const userAttributes = await authX.getUserAttributes();
                    
                    const files = await Files({
                        accessToken:accessToken,
                        sub:userAttributes.sub,
                        id:id,
                    })
                    //console.log('files', files);
    
                    if(files) {
                        //Success
                        //console.log('renderOrder - files success', files);
                        //console.log('URL', files.url)
                        window.open(files.url, '_blank');
                    }
                    else {
                        //Fail
                        console.log('renderOrder - No files... handle')
                        throw new Error(`Autentieringsfel`); //Bubble error handling
                    }
                } catch (error) {
                    console.log('renderOrder - HANDLE ERROR HERE (files-get)', error);
                    alert('Unhandled error on FILES');
                }

                //Re-enable button
                currentTarget.textContent = text;
            });
            row.appendChild(col1);

            const col2 = document.createElement('div');
            col2.classList.add('width8');
            col2.innerHTML = `${d.toLocaleDateString('se-SE', options)}`;
            row.appendChild(col2);

            const col3 = document.createElement('div');
            col3.classList.add('width12');
            col3.innerHTML = `${doc.template.name}, ${doc.template.version}`;
            row.appendChild(col3);

            const col4 = document.createElement('div');
            if(!doc.hasOwnProperty('signaturesData')) {
                col4.classList.add('width12');
                col4.setAttribute('style', 'color:red;');
                col4.innerHTML = `Error`;
            }
            else {
                col4.classList.add('width12', 'textHover');

                //NEW
                let time = '';
                if(doc.hasOwnProperty('signatures') && doc.signatures.length > 0) {
                    time = `#${doc.signatures[0].time}`;
                }

                let strSk = `${doc.sk}${time}`;

                //col4.setAttribute('data-id', doc.sk);
                col4.setAttribute('data-id', strSk);
                col4.innerHTML = `Skapa Signatur-PDF`;
                col4.addEventListener('click', async (e) => {
                    e.preventDefault();
    
                    //console.log('Click-> Download PDF', e.currentTarget);
                    const currentTarget = e.currentTarget;
                    const text = currentTarget.textContent; //Filename
                    const pauseText = `Please wait...`;
                    
                    try {
                        //Prevent dbl-click
                        if(currentTarget.innerHTML === pauseText) {
                            //Process is already running. Avoiid dbl-click
                            //console.log('Returning...');
                            return;
                        }
                        currentTarget.textContent = pauseText;
    
                        //console.log('time to load SIGNATURE', e.currentTarget);
                        const id = e.currentTarget.getAttribute('data-id');
                        //console.log('id', id);
    
                        const accessToken = await authX.getTokens();
                        //console.log('accessToken', accessToken);
            
                        //Need sub....
                        const userAttributes = await authX.getUserAttributes();
                        //console.log('userAttributes', userAttributes);
                        //console.log('sub', userAttributes.sub);
    
                        //Need document
                        const input = {
                            accessToken:accessToken,
                            sub:userAttributes.sub,
                            id:id,
                        };
                        if(doc.hasOwnProperty(signatures) && doc.signatures !== undefined) input.signatures = doc.signatures;
    
                        const signatures = await Signatures(input)
                        console.log('signatures', signatures);
            
                        if(signatures) {
                            //Success
                            //console.log('renderOrder - signatures success', signatures);
    
                            //Create URL
                            var blobURL = URL.createObjectURL(signatures.blob);
                            //console.log('renderOrder - blobURL', blobURL);
    
                            //Create download and rename file
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = blobURL;
                            a.download = signatures.filename;
                            a.click();
                            window.URL.revokeObjectURL(blobURL);
                        }
                        else
                        {
                            //Fail
                            console.log('renderOrder - signatures error. Probably no receipt found)', signatures);
                            //throw new Error(`Autentieringsfel XXX.`); //Bubble error handling
    
                            throw new Error(`No signature found`); //Bubble error handling
                        }
                    } catch (error) {
                        console.log('renderOrder - Error creating Signatur PDF', error);
    
                        //console.log('HANDLE ERROR HERE (files-get)', error);
                        //alert('Problem getting files.')
    
                        alert('Problem getting signature. Probably Ester. Will be fixed when we have more orders in')
                    }
    
                    currentTarget.textContent = text;
                });
            }
            row.appendChild(col4);

            elemDocuments.appendChild(row);
        }

        root.appendChild(elemDocuments);

        //--- Actions div ---
        let statusSliderInputDefaultValue;
        switch(order.status) {
            case "PENDING": statusSliderInputDefaultValue = 0; break;
            case "COMPLETED": statusSliderInputDefaultValue = 1; break;
            default: console.log('ERROR');
        }

        //Actions container
        const elemActions = document.createElement('div');
        elemActions.classList.add('orderSection');
        elemActions.setAttribute('style', 'max-width:18em');
        elemActions.innerHTML = `<h3>Actions</h3>
        <div id='actionContainer1' class='sliderContainer'></div>`;

        //actionContainer1 Label
        const statusSliderLabel = document.createElement('label');
        statusSliderLabel.id = "sliderLabel";
        statusSliderLabel.for = "slider";
        statusSliderLabel.textContent = `Ändra status (${order.status})`;
        elemActions.querySelector('#actionContainer1').appendChild(statusSliderLabel);

        //actionContainer1 Input / range
        const statusSlider = document.createElement('div');
        statusSlider.classList.add('slider');
        const statusSliderInput = document.createElement('input');
        statusSliderInput.classList.add('sliderThumb');
        statusSliderInput.id = "slider";
        statusSliderInput.type = 'range';
        statusSliderInput.min = 0;
        statusSliderInput.max = 1;
        statusSliderInput.value = statusSliderInputDefaultValue;
        statusSliderInput.step = 1;
        statusSliderInput.setAttribute('data-default', order.status);
        statusSliderInput.addEventListener('input', async e => {
            e.preventDefault;
            const currentValue = e.currentTarget.getAttribute('data-default'); //Get current value
            try {
                //Translate
                let newValue;
                switch(e.currentTarget.value) {
                    case "0": newValue = 'PENDING'; break;
                    case "1": newValue = 'COMPLETED'; break;
                    default: console.log('ERROR');
                }
                //Add/remove UI class
                if(currentValue !== newValue) statusSliderLabel.classList.add('sliderHint');
                else statusSliderLabel.classList.remove('sliderHint');
                //Hint next status
                statusSliderLabel.textContent = `Ändra status (${newValue})`;
            } catch (error) {
                console.log('slideError', error);
                statusSliderLabel.textContent = `ERROR! Ändra status (?)`;
            }
        });
        statusSliderInput.addEventListener('change', async e => {
            //Action event (Update DB)
            e.preventDefault;
            const slider = e.currentTarget;
            let currentValue = slider.getAttribute('data-default'); //For UI
            //Disable
            slider.disabled = true;
            statusSliderLabel.textContent = `Ändra status (...)`;
            //Update
            try {
                const accessToken = await authX.getTokens();
                //Translate
                let status;
                switch(e.currentTarget.value) {
                    case "0": status = 'PENDING'; break;
                    case "1": status = 'COMPLETED'; break;
                    default: throw new Error(`Invalid status: ${e.currentTarget.value}`) 
                }
                //Update
                const response = await AdminOrders({
                    accessToken:accessToken,
                    id: order.sk,
                    method: 'PATCH',
                    data: [{
                        status:status,
                    }],
                })
                //Handle response
                if(response) {
                    //console.log('pa - order success', response);
                    //console.log('response.data', response.data);
                    //console.log('response.data.status', response.data.status);
                    statusSliderLabel.classList.remove('sliderHint'); //Remove class
                    statusSliderInput.setAttribute('data-default', response.data.status); //Change data-default
                    currentValue = response.data.status; //Change label
                    statusSliderLabel.textContent = `Ändra status (${currentValue})`;
                    document.querySelector('#orderListStatus').textContent = currentValue; //Change orderList
                }
                else {
                    console.log('pa - order - Error?');
                    throw new Error(`Autentieringsfel`);
                } 
            } catch (error) {
                console.log('pa - Error order', error);
                statusSliderLabel.textContent = `ERROR! Ändra status (?)`;
            }
            //Re-enable
            slider.disabled = false;
        })
        statusSlider.appendChild(statusSliderInput);
        elemActions.querySelector('#actionContainer1').appendChild(statusSlider);
        root.appendChild(elemActions);
        return root;
    }

    function renderError(o) {
        console.log('renderError - init', o);

        const root = document.createElement('div');
        root.id = 'errorContainer';
        root.classList.add('error')
        root.innerHTML = `
        <h2>Error</h2>
        <p>
            ${o.message}
        </p>`;

        return root;
    }

    function renderSubmissions(data, signaturesData) {
        console.log('renderSubmissions - init');
        console.log(data);
        console.log('signaturesData', signaturesData);

        let htmlLastEvaluatedKey = '';
        //if(data.hasOwnProperty('LastEvaluatedKey')) htmlLastEvaluatedKey = ' (dev) -> This is only a subset...'

        //Sort orders on createdAt, descending order
        data.items.sort(function (a, b) {
            return b.createdAt.localeCompare(a.createdAt);
        });

        const root = document.createElement('div');
        root.id = 'submissions';
        root.classList.add('submissions');
        root.innerHTML = `
        <h2>Documents${htmlLastEvaluatedKey}</h2>
        <div class="menuHeader menuRow menu smaller">
            <div class="width24">ID</div>
            <div class="width16">Ref</div>
            <div class="width8">Skapad</div>
            <div class="width16">Template</div>
            <div class="width16">PDF</div>
            <div class="width16">Signatures</div>
            <div class="width8">Status</div>
            <div class="width8">&nbsp;</div>
        </div>`;

        for(const item of data.items) {
            //Prepare createdAt date
            const d = new Date(item.createdAt);
            const options = {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            };

            let template = '';
            if(item.hasOwnProperty('template')) template = `${item.template.name}, ${item.template.version}`;

            let pdf = '';
            if(item.hasOwnProperty('pdf')) pdf = item.pdf.filename;
            else pdf = '<span style="color:#f32b2b">NO PDF CREATED</span>';

            const signatures = signaturesData.items.filter(x => x.data.ref === item.ref);
            console.log('signatures (array)', signatures);

            let htmlSignatures = '';
            let htmlAddToCart = '&nbsp;';
            let classCart = '';

            if(signatures.length === 0) {
                htmlSignatures = '<span style="color:#f32b2b">NOT SIGNED</span>';
                htmlAddToCart = 'Add to cart';
                classCart = 'actionCart';
            }
            else {
                const signatures = signaturesData.items.filter(x => x.data.ref === item.ref);
                for(const s of signatures) {
                    if(s.status === 'COMPLETED') htmlSignatures += `<span style="color:#44bb66">${s.ref}</span>`;
                    else htmlSignatures += `<span style="color:#f32b2b">${s.ref}</span>`;
                }
            }

            //Render
            const row = document.createElement('div');
            row.id = `submission|${item.sk}`;
            row.setAttribute('data-sk', item.sk);
            row.setAttribute('data-ref', item.ref);
            row.classList.add('menuItem', 'menuRow', 'menu', 'smaller');
            row.innerHTML = `
                <div class="width24 actionDocument">${item.sk}</div>
                <div class="width16">${item.ref}</div>
                <div class="width8">${d.toLocaleDateString('se-SE', options)}</div>
                <div class="width16">${template}</div>
                <div class="width16">${pdf}</div>
                <div class="width16">${htmlSignatures}</div>
                <div class="width8">${item.status}</div>
                <div class="width8 ${classCart}">${htmlAddToCart}</div>
            `;

            row.querySelector('.actionDocument').addEventListener('click', (async e => {
                e.preventDefault();
                console.log('renderSubmission - CLICK-> Get order-> ', e.currentTarget.parentNode.id);

                //Hide cart
                removeCart();

                //Loading message
                const accountContainer = document.getElementById('accountContainer');
                const referenceNode = document.getElementById('accountMain'); 
                let msgBox = renderMessageBox({
                    message:'Loading...',
                });

                try {
                    accountContainer.insertBefore(msgBox, referenceNode);
                    referenceNode.replaceChildren();

                    //const id = e.currentTarget.id.split('|')[1];
                    const id = e.currentTarget.parentNode.id.split('|')[1];
                    console.log('id', id);

                    const accessToken = await authX.getTokens();
                    const userAttributes = await authX.getUserAttributes();

                    //console.log('accessToken', accessToken);
                    console.log('userAttributes', userAttributes);
                    console.log('sub', userAttributes.sub);

                    let sub = userAttributes.sub;
                    if(sub === 'c557f009-414b-490d-bf9a-7f5afc91b570') sub = '49d4758e-48cf-471e-a225-ed97d5f03b6e'; //DEV ACCT

                    const response = await Submissions({
                        accessToken:accessToken,
                        sub:userAttributes.sub,
                        pk: `${sub}#DOCUMENT`,
                        sk:id,
                    })
                    console.log('submission -> ', response);
        
                    if(response) {
                        const submissionSignatures = signaturesData.items.filter(x => x.data.ref === response.ref);
                        referenceNode.replaceChildren(renderSubmission(response, submissionSignatures));
                    }
                    else {
                        //Fail 
                        console.log('renderOrders - No orders found - Error?', order);
                        throw new Error(`Autentieringsfel`);
                    }
                } catch (error) {
                    console.log('renderOrders - click event error, Unhandled', error);
                    alert('Unhandled error on ORDERS');
                }
                
                //Remove msgBox if still exists on DOM
                if(accountContainer.contains(msgBox)) accountContainer.removeChild(msgBox);
                msgBox = null;
            }))

            //
            if(signatures.length === 0) {
                row.querySelector('.actionCart').addEventListener('click', (async e => {
                    e.preventDefault();
                    //console.log('actionCart click', e.currentTarget.parentNode.id);

                    const id = e.currentTarget.parentNode.id.split('|')[1];
                    //console.log('id', id);

                    const ref = e.currentTarget.parentNode.getAttribute('data-ref');
                    console.log('REF', ref);

                    //Create cart parameter if not exists (class?)
                    if(!ACCOUNT.hasOwnProperty('cart')) ACCOUNT.cart = [];
                    console.log('ACCOUNT.cart', ACCOUNT.cart);

                    //Check for duplicate
                    //const duplicate = ACCOUNT.cart.filter(x => x.id === id);
                    
                    if(ACCOUNT.cart.filter(x => x.id === id).length === 0) {
                        ACCOUNT.cart.push({
                            id:id,
                            ref:ref,
                        });
                        renderCart();
                    }
                    else if(ACCOUNT.cart.length > 0) renderCart();
                }))
            }

            root.appendChild(row);
        }

        //Add btnGetNextPage if there are more items available
        if(data.hasOwnProperty('LastEvaluatedKey')) {
            const btnGetNextPage = document.createElement('button');
            btnGetNextPage.classList.add('buttonStyle', 'buttonStyle1');
            btnGetNextPage.setAttribute('style', 'margin-top:1em');
            btnGetNextPage.textContent = 'Next page';

            btnGetNextPage.addEventListener( 'click', async e => {
                e.preventDefault();

                const documents = await getSubmissions({
                    lastEvaluatedKey:data.LastEvaluatedKey,
                });

                //Re-render accountMain
                document.getElementById('accountMain').replaceChildren(renderSubmissions(documents, signaturesData));
            });
            root.appendChild(btnGetNextPage);
        }







        // for(const order of data) {
        //     //Prepare createdAt date
        //     const d = new Date(order.createdAt);
        //     const options = {
        //         year: '2-digit',
        //         month: '2-digit',
        //         day: '2-digit',
        //     };
        //     //console.log(d.toLocaleDateString('se-SE', options));

        //     //Get tags if available (orgId, contactName)
        //     const tags = {};
        //     if(order.hasOwnProperty('tags')) {
        //         for (const [key, value] of order.tags) {
        //             if(key === 'orgId') tags.orgId = decodeURIComponent(value);
        //             if(key === 'contactName') tags.contactName = decodeURIComponent(value);
        //         }
        //     }
            
        //     const row = document.createElement('div');
        //     row.id = `order|${order.sk}`;
        //     row.classList.add('menuItem', 'menuRow', 'menu');
        //     row.innerHTML = `
        //         <div class="width24">${order.sk}</div>
        //         <div class="width8">${tags.orgId}</div>
        //         <div class="width16">${tags.contactName}</div>
        //         <div class="width8">${d.toLocaleDateString('se-SE', options)}</div>
        //         <div class="width8">${order.status}</div>
        //     `;
        //     row.addEventListener('click', ( async (e) => {
        //         e.preventDefault();
        //         //console.log('renderOrders - CLICK-> Get order-> ', e.currentTarget.id);

        //         //Loading message
        //         const accountContainer = document.getElementById('accountContainer');
        //         const referenceNode = document.getElementById('accountMain');
        //         let msgBox = renderMessageBox({
        //             message:'Loading...',
        //         });

        //         try {
        //             accountContainer.insertBefore(msgBox, referenceNode);
        //             referenceNode.replaceChildren();

        //             const id = e.currentTarget.id.split('|')[1];
        //             //console.log('id', id);

        //             const accessToken = await authX.getTokens();
        //             //console.log('accessToken 333', accessToken);

        //             const userAttributes = await authX.getUserAttributes();
        //             //console.log('userAttributes', userAttributes);
                
        //             const order = await Orders({
        //                 accessToken:accessToken,
        //                 sub:userAttributes.sub,
        //                 id:id,
        //             })
        //             //console.log('renderOrders - order', order);
        
        //             if(order) {
        //                 //Success
        //                 //console.log('renderOrders - order success', order);
                        
        //                 //render...
        //                 //renderOrder(order);
        //                 referenceNode.replaceChildren(renderOrder(order));
        //             }
        //             else {
        //                 //Fail 
        //                 console.log('renderOrders - No orders found - Error?', order);
        //                 throw new Error(`Autentieringsfel`);
        //             }
        //         } catch (error) {
        //             console.log('renderOrders - click event error, Unhandled', error);
        //             alert('Unhandled error on ORDERS');
        //         }

        //         //Remove msgBox if still exists on DOM
        //         if(accountContainer.contains(msgBox)) accountContainer.removeChild(msgBox);
        //         msgBox = null;
        //     }))
        //     root.appendChild(row);
        // }

        

        return root;
    }

    function removeCart() {
        console.log('removeCart init - Maybe add this to accountMain later');

        const elem = document.getElementById('cartContainer');
        if(elem) {
            elem.remove();
        }
    }

    function renderCart(params) {
        console.log('renderCart init', params);
        console.log(ACCOUNT);

        const elemId = 'cartContainer';
        let root = document.getElementById(elemId);
        if(root) root.remove(); //remove existing

        if(!ACCOUNT.hasOwnProperty('cart')) return;
        if(ACCOUNT.cart.length === 0) return;

        //There are item(s) in cart. Create Element!
        root = document.createElement('div');
        root.id = elemId;
        root.setAttribute('style', 'margin:1em 1em 1em 1em;');
        root.classList.add('cartContainer');
        root.innerHTML = `
            <h2>Begär signatur</h2>
            <p>
                Lägg till de dokument som skall signeras.<br>
                Välj om en order skall skapas eller om bara signaturer skall efterfrågas.<br>
                Generera url-token. Kopiera länk och skicka till mottagare som sedan kan signera med BankID.
            </p>
            <div id="cartDocuments" class="cartDocuments">
                <h3 id="cartDocumentsH3">Dokument</h3>
            </div>
            <div id="cartSettings" class="cartSettings">
                <h3 >Inställningar</h3>
                <ul class="" style="list-style-type:none;">
                    <li>
                        <span>Skapa order</span><input id="createOrder" type="checkbox" style="accent-color:#666;" name="createOrder" checked>
                    </li>
                </ul>
            </div>
            `;

        const ul = document.createElement('ul');
        ul.setAttribute('style', 'list-style-type:none;')
        for(let i = 0; i<ACCOUNT.cart.length; i++) {
            const item = ACCOUNT.cart[i];

            const li = document.createElement('li');
            li.setAttribute('data-id', item.id);

            const span1 = document.createElement('span');
            span1.setAttribute('style', 'margin-bottom: 1em;');
            span1.innerHTML = `${item.id}`;
            li.appendChild(span1);

            const btnRemove = document.createElement('button');
            btnRemove.classList.add('buttonStyle', 'buttonStyle1');
            btnRemove.setAttribute('data-index', i);
            btnRemove.textContent = `Remove`;
            btnRemove.addEventListener('click', e => {
                e.preventDefault();
                console.log('Remove this item', e.currentTarget);
                console.log('ACCOUNT', e.currentTarget.getAttribute('data-index'));

                //Remove item from cart
                ACCOUNT.cart.splice(parseInt(e.currentTarget.getAttribute('data-index')), 1);

                //re-Render
                renderCart({});
            })
            li.appendChild(btnRemove);

            ul.appendChild(li);
        }
        //root.appendChild(ul);

        //root.querySelector('.wee').insertAdjacentHTML('afterend', ul);
        root.querySelector('#cartDocumentsH3').insertAdjacentElement('afterend', ul);


        //Checkbox½ (Order yes/no)




        //JWT token button
        const btnGenerate = document.createElement('button');
        btnGenerate.classList.add('buttonStyle', 'buttonStyle2');
        btnGenerate.setAttribute('style', 'margin-top:2em;')
        btnGenerate.textContent = 'Generate url/token';
        btnGenerate.addEventListener('click', async e => {
            e.preventDefault();
            console.log('Generate JWT', e.currentTarget);
            console.log('ACCOUNT', ACCOUNT);

            const settings = document.querySelector('.cartSettings').getElementsByTagName('input');
            console.log('settings', settings);

            //createOrder
            const elemCreateOrder = settings.namedItem('createOrder');
            console.log('checked?', elemCreateOrder.checked); //true



            try {
                const accessToken = await authX.getTokens();
                const userAttributes = await authX.getUserAttributes();
               
                const response = await AdminSubmissions({
                    accessToken:accessToken,
                    sub:userAttributes.sub,
                    method: 'GET',
                    items: ACCOUNT.cart,
                    settings: [
                        {
                            key:'createOrder',
                            value:elemCreateOrder.checked, //true/false
                        },
                        {
                            key:'maxSignatures',            //Set number of signatures. Prevent reuse of Token
                            value:1,                        //Only one is possible yet
                        }
                    ],
                })
                console.log('jwt -> ', response);
    
                if(response) {
                    console.log('jwt success');

                    //const url = `https://pressapremien.se/signera.html?token=${response.token}`;

                    let url;
                    (process.env.STAGE === 'production') ? url = `https://pressapremien.se/signera.html?token=${response.token}` : url = `http://localhost:3000/signera.html?token=${response.token}`;
                    

                    const elemLinkContainer = document.createElement('div');
                    elemLinkContainer.setAttribute('style', 'margin-top:1em;')
                    const elemLink = document.createElement('a');
                    elemLink.classList.add('linkStyle1');
                    elemLink.href = url;
                    elemLink.innerText = url;
                    elemLink.setAttribute('target', '_blank');
                    elemLinkContainer.appendChild(elemLink);

                    const referenceNode = document.querySelector(`#${elemId}`);

                    referenceNode.appendChild(elemLinkContainer);



                }
                else {
                    //Fail 
                    console.log('jwt - Error?');
                    throw new Error(`Autentieringsfel`);
                } 
            } catch (error) {
                console.log('Error creating JWT', error);
            }











        });
        root.appendChild(btnGenerate);










        container.appendChild(root);








    }

    function renderSubmission(data, signaturesData) {
        console.log('renderSubmission - init');
        console.log(data);
        console.log(signaturesData);
        console.log('data.signatures', data.signatures); //undefined on old

        const completedSignatures = signaturesData.filter(x => x.status === 'COMPLETED');
        console.log('completedSignatures', completedSignatures.length);

        const d = new Date(data.createdAt);
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        };
        //console.log(d.toLocaleDateString('se-SE', options));

        //Get tags if available (orgId, contactName)
        const tags = {};
        if(data.hasOwnProperty('tags')) {
            for (const [key, value] of data.tags) {
                if(key === 'orgId') tags.orgId = decodeURIComponent(value);
                if(key === 'contactName') tags.contactName = decodeURIComponent(value);
            }
        }
        console.log('tags', tags);

        let htmlPDF;
        //if(data.hasOwnProperty('pdf')) htmlPDF = `<span>${data.pdf.filename}<span>`;
        //else htmlPDF = '<span style="color:#f32b2b">NO PDF GENERATED</span>';
        //(data.hasOwnProperty('pdf')) ? htmlPDF = `<span class="actionDownloadPDF linkStyle1" data-id="${data.sk}" data-ref="${data.ref}.pdf">${data.pdf.filename}<span>` : htmlPDF = `<span class="actionGeneratePDF linkStyle1" data-sk="${data.sk}" data-ref="${data.ref}">Skapa och ladda ner osignerad PDF</span>`;
        //(data.hasOwnProperty('pdf')) ? htmlPDF = `<span>${data.pdf.filename}<span>` : htmlPDF = `<span class="actionGeneratePDF linkStyle1" data-sk="${data.sk}" data-ref="${data.ref}">Skapa och ladda ner osignerad PDF</span>`;
        //(data.hasOwnProperty('pdf')) ? htmlPDF = `<span>${data.pdf.filename}<span>` : htmlPDF = `<span class="actionGenerateUnsignedPDF linkStyle1 actionCart" data-sk="${data.sk}" data-ref="${data.ref}">Skapa och ladda ner osignerad PDF</span>`;
        (signaturesData.length > 0) ? htmlPDF = `<span>${data.pdf.filename}<span>` : htmlPDF = `&nbsp;`;

        //Provide Download-link if no signatures exist
        let htmlGenerateUnsigned = '';
        if(signaturesData.length === 0) htmlGenerateUnsigned = `<li><span>&nbsp;</span><span class="actionGenerateUnsignedPDF linkStyle1" data-id="${data.sk}" data-ref="${data.ref}">Skapa och ladda ner osignerad PDF (underlag)</span></li>`;

        const root = document.createElement('div');
        root.id = 'submissionContainer';
        root.classList.add('submission');
        root.innerHTML = `<h2>Dokument</h2>`;

        const elemMain = document.createElement('div');
        //<li><span>DEV (Remove!):</span><span class="actionGenerateJWT linkStyle1" data-sk="${data.sk}" data-ref="${data.ref}">Skapa JWT (DEV. Remove!)</span></li>
        //<li><span>&nbsp;:</span><span class="actionGeneratePDF linkStyle1" data-sk="${data.sk}" data-ref="${data.ref}">Skapa och ladda ner osignerad PDF</span></li>
        //<li><span>Signaturer:</span><span>${completedSignatures.length}/${signaturesData.length}</span></li>
        elemMain.innerHTML = `
            <ul class="orderList">
                <li><span>Ref:</span><span>${data.ref}</span></li>
                <li><span>Skapad:</span><span>${d.toLocaleDateString('se-SE', options)}</span></li>
                <li><span>PDF:</span>${htmlPDF}</li>
                <li><span>Status:</span><span>${data.status}</span></li>
            </ul>
        `;
        root.appendChild(elemMain);

        root.innerHTML += `<h2 style="margin: 1em 0 .3em 0;">Signaturer</h2>`;

        if(signaturesData.length > 0) {
            for(const item of signaturesData) {
                console.log('ITEM', item);
                
                let time = '';
                if(item.data.hasOwnProperty('time')) time = `#${item.data.time}`;
                //console.log('TIME', time);

                let elemLink = '';
                if(item.status === 'COMPLETED') elemLink = `<li><span>&nbsp;</span><span class="actionGenerateVerificationPDF linkStyle1" data-id="${data.sk}${time}">Skapa Signatur-PDF</span></li>`;
                //<li><span>&nbsp;</span><span class="actionGenerateVerificationPDF linkStyle1" data-id="${data.sk}${time}">Skapa Signatur-PDF</span></li>

                const itemD = new Date(item.createdAt);
                const elemS = document.createElement('div');
                elemS.innerHTML = `
                <ul class="orderList ulStyle1">
                    <li><span>Ref:</span><span>${item.ref}</span></li>
                    <li><span>Skapad:</span><span>${itemD.toLocaleDateString('se-SE', options)}</span></li>
                    <li><span>Status:</span><span>${item.status}</span></li>
                    ${elemLink}
                </ul>`;
                root.appendChild(elemS);
            }
        }
        else {
            //No signatures
            root.innerHTML += `<span class="actionGenerateUnsignedPDF linkStyle1" data-id="${data.sk}" data-ref="${data.ref}">Skapa och ladda ner osignerad PDF (underlag)</span>`;
        }
        
        //EventListeners
        //Generate PDF element        
        // if(!data.hasOwnProperty('pdf')) {
        //     console.log('RENDER GENERATE PDF BUTTON');

        //     const elem = root.querySelector('.actionGeneratePDF');
        //     console.log('elem actionGeneratePDF', elem);
        //     //elem.setAttribute('style', 'color:#fb00ff;');
            
        //     elem.addEventListener('click', async e => {
        //         e.preventDefault();
        //         console.log('click', e.currentTarget);

        //         const elem = e.currentTarget;

        //         const pauseMessage = 'Please wait...';

        //         if(elem.textContent !== pauseMessage) {
        //             //Loading message
        //             const originalMessage = elem.textContent;
        //             elem.textContent = pauseMessage; //Prevent dbl-click
                    
        //             try {
        //                 const accessToken = await authX.getTokens();
        //                 const userAttributes = await authX.getUserAttributes();

        //                 const id = elem.getAttribute('data-sk');

        //                 //let sub = userAttributes.sub;
        //                 //if(sub === 'c557f009-414b-490d-bf9a-7f5afc91b570') sub = '49d4758e-48cf-471e-a225-ed97d5f03b6e'; //DEV ACCT

        //                 const response = await Files2({
        //                     accessToken:accessToken,
        //                     sub:userAttributes.sub,
        //                     method: 'PUT',
        //                     //pk: `${sub}#DOCUMENT`,
        //                     //sk:sk,
        //                     id:id,
        //                 })
        //                 console.log('files -> ', response);
            
        //                 if(response) {
        //                     //const submissionSignatures = signaturesData.items.filter(x => x.data.ref === response.ref);
        //                     //referenceNode.replaceChildren(renderSubmission(response, submissionSignatures));

        //                     console.log('put file success');

        //                     //Create URL
        //                     const blobURL = URL.createObjectURL(response.blob);
        //                     console.log('renderOrder - blobURL', blobURL);

        //                     //Create download and rename file
        //                     var a = document.createElement("a");
        //                     document.body.appendChild(a);
        //                     a.style = "display: none";
        //                     a.href = blobURL;
        //                     a.download = response.filename;
        //                     a.click();
        //                     window.URL.revokeObjectURL(blobURL);
        //                 }
        //                 else {
        //                     //Fail 
        //                     console.log('put file - Error?');
        //                     throw new Error(`Autentieringsfel`);
        //                 }                            
        //             } catch (error) {
        //                 console.log('Error creating PDF', error);
        //             }
        //             elem.textContent = originalMessage;
        //         }
        //         else console.log('Work in progress');





                




                
        //         //const accountContainer = document.getElementById('accountContainer');
        //         //const referenceNode = document.getElementById('accountMain'); 
        //         //let msgBox = renderMessageBox({
        //         //    message:'Loading...',
        //         //});





        //         // 

        //         // try {
        //         //     accountContainer.insertBefore(msgBox, referenceNode);
        //         //     referenceNode.replaceChildren();

        //         //     const id = e.currentTarget.id.split('|')[1];
        //         //     console.log('id', id);

        //         //     const accessToken = await authX.getTokens();
        //         //     const userAttributes = await authX.getUserAttributes();

        //         //     //console.log('accessToken', accessToken);
        //         //     console.log('userAttributes', userAttributes);
        //         //     console.log('sub', userAttributes.sub);

        //         //     let sub = userAttributes.sub;
        //         //     if(sub === 'c557f009-414b-490d-bf9a-7f5afc91b570') sub = '49d4758e-48cf-471e-a225-ed97d5f03b6e'; //DEV ACCT

        //         //     const response = await Submissions({
        //         //         accessToken:accessToken,
        //         //         sub:userAttributes.sub,
        //         //         pk: `${sub}#DOCUMENT`,
        //         //         sk:id,
        //         //     })
        //         //     console.log('submission -> ', response);
        
        //         //     if(response) {
        //         //         const submissionSignatures = signaturesData.items.filter(x => x.data.ref === response.ref);
        //         //         referenceNode.replaceChildren(renderSubmission(response, submissionSignatures));
        //         //     }
        //         //     else {
        //         //         //Fail 
        //         //         console.log('renderOrders - No orders found - Error?', order);
        //         //         throw new Error(`Autentieringsfel`);
        //         //     }
        //         // } catch (error) {
        //         //     console.log('renderOrders - click event error, Unhandled', error);
        //         //     alert('Unhandled error on ORDERS');
        //         // }
                
        //         // //Remove msgBox if still exists on DOM
        //         // if(accountContainer.contains(msgBox)) accountContainer.removeChild(msgBox);
        //         // msgBox = null;
        //     })
        // }

        //Download PDF
        if(root.querySelector('.actionDownloadPDF')) {
            const elem = root.querySelector('.actionDownloadPDF');
            //elem.setAttribute('style', 'color:#fb00ff;');
            
            elem.addEventListener('click', async e => {
                e.preventDefault();
                console.log('click', e.currentTarget);

                //const elem = e.currentTarget;
                const text = elem.textContent; //Filename
                const pauseText = 'Please wait...';

                //Process is already running. Avoid dbl-click
                if(elem.textContent === pauseText) return;
                
                try {
                    elem.textContent = pauseText;

                    const accessToken = await authX.getTokens();
                    const userAttributes = await authX.getUserAttributes();

                    const id = elem.getAttribute('data-id');
                    const ref = elem.getAttribute('data-ref');

                    console.log('id', id);
                    console.log('ref', ref);
                    
                    const files = await Files({
                        accessToken:accessToken,
                        sub:userAttributes.sub,
                        id:ref,
                    })
                    console.log('files', files);
    
                    if(files) {
                        //Success
                        //console.log('renderOrder - files success', files);
                        console.log('URL', files.url)
                        window.open(files.url, '_blank');
                    }
                    else {
                        //Fail
                        console.log('renderOrder - No files... handle')
                        throw new Error(`Autentieringsfel`); //Bubble error handling
                    }




                } catch (error) {
                    console.log('xxx - HANDLE ERROR HERE (files-get)', error);
                    alert('Unhandled error on FILES');
                }
    
                elem.textContent = text;
            })
        }

        //Generate verify
        if(root.querySelector('.actionGenerateVerificationPDF') !== null) {
            const elem = root.querySelector('.actionGenerateVerificationPDF');
            //elem.setAttribute('style', 'color:#fb00ff;');
            
            elem.addEventListener('click', async e => {
                e.preventDefault();
                //console.log('click', e.currentTarget);

                //const elem = e.currentTarget;
                const text = elem.textContent; //Filename
                const pauseText = 'Please wait...';

                if(elem.textContent === pauseText) {
                    //Process is already running. Avoid dbl-click
                    return;
                }

                try {
                    elem.textContent = pauseText;
    
                    //console.log('time to load SIGNATURE', e.currentTarget);
                    const id = e.currentTarget.getAttribute('data-id');
                    console.log('id', id); //49d4758e-48cf-471e-a225-ed97d5f03b6e#DOCUMENT#4b7036cf-e9d2-46eb-b13c-1aadab8dc129#1718294033321

                    //4b7036cf-e9d2-46eb-b13c-1aadab8dc129#1718294033321
    
                    const accessToken = await authX.getTokens();
                    console.log('accessToken', accessToken);
        
                    //Need sub....
                    const userAttributes = await authX.getUserAttributes();
                    console.log('userAttributes', userAttributes);
                    console.log('sub', userAttributes.sub);
    
                    //Need document
    
                    const signatures = await Signatures({
                        accessToken:accessToken,
                        sub:userAttributes.sub,
                        id:id,
                        //pk:,
                        sk:id,
                    })
                    console.log('signatures', signatures);
        
                    if(signatures) {
                        //Success
                        //console.log('renderOrder - signatures success', signatures);
    
                        //Create URL
                        var blobURL = URL.createObjectURL(signatures.blob);
                        //console.log('renderOrder - blobURL', blobURL);
    
                        //Create download and rename file
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = blobURL;
                        a.download = signatures.filename;
                        a.click();
                        window.URL.revokeObjectURL(blobURL);
                    }
                    else {
                        //Fail
                        console.log('actionGenerateVerificationPDF - signatures error.', signatures);
                        //throw new Error(`Autentieringsfel XXX.`); //Bubble error handling
    
                        throw new Error(`No signature found`); //Bubble error handling
                    }
                } catch (error) {
                    console.log('actionGenerateVerificationPDF - Error creating Signatur PDF', error);
    
                    //console.log('HANDLE ERROR HERE (files-get)', error);
                    //alert('Problem getting files.')
    
                    alert('actionGenerateVerificationPDF - Problem getting signature. Probably Ester. Will be fixed when we have more orders in')
                }
    
                elem.textContent = text;
            })
        }

        //Render PDF (not from DB). Unsigned label
        if(root.querySelector('.actionGenerateUnsignedPDF')) {
            console.log('actionGenerateUnsignedPDF');

            const elem = root.querySelector('.actionGenerateUnsignedPDF');
            //elem.setAttribute('style', 'color:#fb00ff;');
            
            elem.addEventListener('click', async e => {
                e.preventDefault();
                console.log('click', elem);

                //const elem = e.currentTarget;
                const text = elem.textContent; //Filename
                const pauseMessage = 'Please wait...';
               
                if(elem.textContent === pauseMessage) return;

                try {
                    const accessToken = await authX.getTokens();
                    const userAttributes = await authX.getUserAttributes();

                    const id = elem.getAttribute('data-id');
                
                    const response = await Files2({
                        accessToken:accessToken,
                        sub:userAttributes.sub,
                        method: 'PUT',
                        id:id,
                    })
                    console.log('files2 -> ', response);
        
                    if(response) {
                        console.log('put file success');

                        //Create URL
                        const blobURL = URL.createObjectURL(response.blob);
                        console.log('renderOrder - blobURL', blobURL);

                        //Create download and rename file
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = blobURL;
                        a.download = response.filename;
                        a.click();
                        window.URL.revokeObjectURL(blobURL);
                    }
                    else {
                        //Fail 
                        console.log('put file - Error?');
                        throw new Error(`Autentieringsfel`);
                    }
                }
                catch (error) {
                    console.log('Error creating PDF', error);
                }

                elem.textContent = text;
            })
        }
    
        //Create PDF copy W.O. Signatures
        // if(root.querySelector('.actionGenerateUnsignedPDF')) {
        //     const elem = root.querySelector('.actionGenerateUnsignedPDF');
            
        //     elem.addEventListener('click', async e => {
        //         e.preventDefault();
                
        //         const text = elem.textContent; //Filename
        //         const pauseText = 'Please wait...';

        //         //Process is already running. Avoid dbl-click
        //         if(elem.textContent === pauseText) return;

        //         try {
        //             elem.textContent = pauseText;
    
        //             //console.log('time to load SIGNATURE', e.currentTarget);
        //             const id = e.currentTarget.getAttribute('data-id');
        //             console.log('id', id);
    
        //             const accessToken = await authX.getTokens();
        //             console.log('accessToken', accessToken);
        
        //             //Need sub....
        //             const userAttributes = await authX.getUserAttributes();
        //             console.log('userAttributes', userAttributes);
        //             console.log('sub', userAttributes.sub);
    
        //             //Need document
        //             const signatures = await Signatures({
        //                 accessToken:accessToken,
        //                 sub:userAttributes.sub,
        //                 id:id,
        //             })
        //             console.log('signatures', signatures);
        
        //             if(signatures) {
        //                 //Success
        //                 //console.log('renderOrder - signatures success', signatures);
    
        //                 //Create URL
        //                 var blobURL = URL.createObjectURL(signatures.blob);
        //                 //console.log('renderOrder - blobURL', blobURL);
    
        //                 //Create download and rename file
        //                 var a = document.createElement("a");
        //                 document.body.appendChild(a);
        //                 a.style = "display: none";
        //                 a.href = blobURL;
        //                 a.download = signatures.filename;
        //                 a.click();
        //                 window.URL.revokeObjectURL(blobURL);
        //             }
        //             else {
        //                 //Fail
        //                 console.log('actionGenerateVerificationPDF - signatures error.', signatures);
        //                 //throw new Error(`Autentieringsfel XXX.`); //Bubble error handling
    
        //                 throw new Error(`No signature found`); //Bubble error handling
        //             }
        //         } catch (error) {
        //             console.log('actionGenerateVerificationPDF - Error creating Signatur PDF', error);
    
        //             //console.log('HANDLE ERROR HERE (files-get)', error);
        //             //alert('Problem getting files.')
    
        //             alert('actionGenerateVerificationPDF - Problem getting signature. Probably Ester. Will be fixed when we have more orders in')
        //         }
    
        //         elem.textContent = text;
        //     })
        // }








        //DEV BUTTON (not working anymore. Compare to Documents)
        // const elemDEV = root.querySelector('.actionGenerateJWT');
        // elemDEV.setAttribute('style', 'color:#fb00ff;');
        // elemDEV.addEventListener('click', async e => {
        //     e.preventDefault();
        //     console.log('click', e.currentTarget);

        //     const elem = e.currentTarget;
        //     const pauseMessage = 'Please wait...';

        //     if(elem.textContent !== pauseMessage) {
        //         //Loading message
        //         const originalMessage = elem.textContent;
        //         elem.textContent = pauseMessage; //Prevent dbl-click
                
        //         try {
        //             const accessToken = await authX.getTokens();
        //             const userAttributes = await authX.getUserAttributes();
        //             const id = elem.getAttribute('data-sk');
        //             const ref = elem.getAttribute('data-ref');

        //             const response = await AdminSubmissions({
        //                 accessToken:accessToken,
        //                 sub:userAttributes.sub,
        //                 method: 'GET',
        //                 items: [{
        //                     id:id,
        //                     ref:ref,
        //                 }],
        //                 // id:id,
        //                 // ref:ref,
        //             })
        //             console.log('jwt -> ', response);
        
        //             if(response) {
        //                 //const submissionSignatures = signaturesData.items.filter(x => x.data.ref === response.ref);
        //                 //referenceNode.replaceChildren(renderSubmission(response, submissionSignatures));

        //                 console.log('jwt success');

        //                 const url = `https://pressapremien.se/signera.html?token=${response.token}`;


        //                 const elemLinkContainer = document.createElement('div');
        //                 const elemLink = document.createElement('a');
        //                 elemLink.classList.add('linkStyle1');
        //                 elemLink.href = url;
        //                 elemLink.innerText = url;
        //                 elemLink.setAttribute('target', '_blank');
        //                 elemLinkContainer.appendChild(elemLink);

        //                 const referenceNode = document.querySelector('.orderList');

        //                 referenceNode.parentNode.appendChild(elemLinkContainer);



        //             }
        //             else {
        //                 //Fail 
        //                 console.log('jwt - Error?');
        //                 throw new Error(`Autentieringsfel`);
        //             } 
        //         } catch (error) {
        //             console.log('Error creating JWT', error);
        //         }
        //         elem.textContent = originalMessage;
        //     }
        //     else console.log('Work in progress');
        // })
       






        // const elemDocuments = document.createElement('div');
        // elemDocuments.classList.add('submissionDocuments');
        // elemDocuments.innerHTML = `
        //     <h3>Documents</h3>
        //     <div class="menuHeader menuRow menu">
        //         <div class="width12">Filnamn</div>
        //         <div class="width8">Skapad</div>
        //         <div class="width12">Template</div>
        //         <div class="width12">Signatur</div>
        //     </div>
        // `;

        // for(const doc of order.documents) {
        //     //console.log('doc', doc);

        //     const d = new Date(order.createdAt);
        //     const options = {
        //         year: '2-digit',
        //         month: '2-digit',
        //         day: '2-digit',
        //       };
        //     //console.log(d.toLocaleDateString('se-SE', options));

        //     const row = document.createElement('div');
        //     row.id = `document|${doc.ref}`;
        //     row.classList.add('menuItem', 'menuRow', 'menu');

        //     const col1 = document.createElement('div');
        //     col1.classList.add('width12', 'textHover');
        //     col1.innerHTML = `${doc.pdf.filename}`;
        //     col1.addEventListener('click', async (e) => {
        //         e.preventDefault();

        //         //console.log('Click-> Download PDF', e.currentTarget);
        //         const currentTarget = e.currentTarget;
        //         const id = currentTarget.textContent; //Filename
        //         const text = currentTarget.textContent; //Filename
        //         const pauseText = `Please wait...`;
        //         //console.log('text', text);

        //         try {
        //             //Prevent dbl-click
        //             if(currentTarget.innerHTML === pauseText) {
        //                 //Process is already running. Avoiid dbl-click
        //                 //console.log('Returning...');
        //                 return;
        //             }

        //             currentTarget.textContent = pauseText;

        //             const accessToken = await authX.getTokens();
        //             const userAttributes = await authX.getUserAttributes();
                    
        //             const files = await Files({
        //                 accessToken:accessToken,
        //                 sub:userAttributes.sub,
        //                 id:id,
        //             })
        //             //console.log('files', files);
    
        //             if(files) {
        //                 //Success
        //                 //console.log('renderOrder - files success', files);
        //                 //console.log('URL', files.url)
        //                 window.open(files.url, '_blank');
        //             }
        //             else {
        //                 //Fail
        //                 console.log('renderOrder - No files... handle')
        //                 throw new Error(`Autentieringsfel`); //Bubble error handling
        //             }
        //         } catch (error) {
        //             console.log('renderOrder - HANDLE ERROR HERE (files-get)', error);
        //             alert('Unhandled error on FILES');
        //         }

        //         //Re-enable button
        //         currentTarget.textContent = text;
        //     });
        //     row.appendChild(col1);

        //     const col2 = document.createElement('div');
        //     col2.classList.add('width8');
        //     col2.innerHTML = `${d.toLocaleDateString('se-SE', options)}`;
        //     row.appendChild(col2);

        //     const col3 = document.createElement('div');
        //     col3.classList.add('width12');
        //     col3.innerHTML = `${doc.template.name}, ${doc.template.version}`;
        //     row.appendChild(col3);

        //     const col4 = document.createElement('div');
        //     col4.classList.add('width12', 'textHover');
        //     col4.setAttribute('data-id', doc.sk)
        //     col4.innerHTML = `Skapa Signatur-PDF`;
        //     col4.addEventListener('click', async (e) => {
        //         e.preventDefault();

        //         //console.log('Click-> Download PDF', e.currentTarget);
        //         const currentTarget = e.currentTarget;
        //         const text = currentTarget.textContent; //Filename
        //         const pauseText = `Please wait...`;
                
        //         try {
        //             //Prevent dbl-click
        //             if(currentTarget.innerHTML === pauseText) {
        //                 //Process is already running. Avoiid dbl-click
        //                 //console.log('Returning...');
        //                 return;
        //             }
        //             currentTarget.textContent = pauseText;

        //             //console.log('time to load SIGNATURE', e.currentTarget);
        //             const id = e.currentTarget.getAttribute('data-id');
        //             //console.log('id', id);

        //             const accessToken = await authX.getTokens();
        //             //console.log('accessToken', accessToken);
        
        //             //Need sub....
        //             const userAttributes = await authX.getUserAttributes();
        //             //console.log('userAttributes', userAttributes);
        //             //console.log('sub', userAttributes.sub);

        //             //Need document

        //             const signatures = await Signatures({
        //                 accessToken:accessToken,
        //                 sub:userAttributes.sub,
        //                 id:id,
        //             })
        //             //console.log('signatures', signatures);
        
        //             if(signatures) {
        //                 //Success
        //                 //console.log('renderOrder - signatures success', signatures);

        //                 //Create URL
        //                 var blobURL = URL.createObjectURL(signatures.blob);
        //                 //console.log('renderOrder - blobURL', blobURL);

        //                 //Create download and rename file
        //                 var a = document.createElement("a");
        //                 document.body.appendChild(a);
        //                 a.style = "display: none";
        //                 a.href = blobURL;
        //                 a.download = signatures.filename;
        //                 a.click();
        //                 window.URL.revokeObjectURL(blobURL);
        //             }
        //             else
        //             {
        //                 //Fail
        //                 console.log('renderOrder - signatures error. Probably no receipt found)', signatures);
        //                 //throw new Error(`Autentieringsfel XXX.`); //Bubble error handling

        //                 throw new Error(`No signature found`); //Bubble error handling
        //             }
        //         } catch (error) {
        //             console.log('renderOrder - Error creating Signatur PDF', error);

        //             //console.log('HANDLE ERROR HERE (files-get)', error);
        //             //alert('Problem getting files.')

        //             alert('Problem getting signature. Probably Ester. Will be fixed when we have more orders in')
        //         }

        //         currentTarget.textContent = text;
        //     });
        //     row.appendChild(col4);

        //     elemDocuments.appendChild(row);
        // }

        //root.appendChild(elemDocuments);

        return root;
    }








    async function renderSignedInPages()  {
        //console.log('renderSignedInPages');

        const getOrders = async () => {
            try {
                //console.log('renderSignedInPages - Fetching orders....');
                const accessToken2 = await authX.getTokens();
                //console.log('renderSignedInPages - accessToken', accessToken2);

                const arrStatuses = [ 'PENDING' ];
    
                const orders = await Orders({
                    accessToken:accessToken2,
                    filter: {
                        statuses: arrStatuses,
                    },
                })
                //console.log('renderSignedInPages - orders', orders);
    
                if(orders) {
                    //Success
                    accountMain.replaceChildren(renderOrders(orders, {
                        statuses: arrStatuses,
                    }));
                    return orders;
                }
                else {
                    //Fail. Currently never landing here
                    console.log('renderSignedInPages - Error 1', orders); //false
                    throw new Error(`Autentieringsfel.`); //Bubble error handling
                }
            }
            catch (error) {
                console.log('renderSignedInPages - order Error 2', error);
                console.log('renderSignedInPages - order Error XXX', error.message);
                alert('Unhandled error on ORDERS')
      
                return false;           
            }
        }

        // const getSubmissions = async () => {
        //     try {
        //         console.log('renderSignedInPages - Fetching submissions....');
        //         const accessToken2 = await authX.getTokens();
        //         //console.log('renderSignedInPages - accessToken', accessToken2);
    
        //         //Need sub....
        //         const userAttributes = await authX.getUserAttributes();
        //         //console.log('userAttributes', userAttributes);
        //         //console.log('sub', userAttributes.sub);
        
        //         //if(GLOBALS.stage !== 'prod') console.log('Get orders now!');
    
        //         const items = await Submissions({
        //             accessToken:accessToken2,
        //             sub:userAttributes.sub,
        //         })
        //         console.log('renderSignedInPages - submissions items', items);
        //         console.log('renderSignedInPages - submissions XXX', orders);

        //         //DEV!!!
        //         if(items.hasOwnProperty('LastEvaluatedKey')) {
        //             console.log('DEV', items.LastEvaluatedKey);

        //             //TRY TO GET FROM HERE

        //             /*
        //             Construct a new Query request, with the same parameters as the previous one. However, this time, take the LastEvaluatedKey value from step 1 and use it as the ExclusiveStartKey parameter in the new Query request.
        //             */

        //             const items2 = await Submissions({
        //                 accessToken:accessToken2,
        //                 sub:userAttributes.sub,
        //                 lastEvaluatedKey:items.LastEvaluatedKey,
        //             })

        //             console.log('items2 CHECK!!!', items2);
        //         }
    
        //         if(items) {
        //             //Success
        //             //accountMain.replaceChildren(renderSubmissions(items));
        //             return items;
        //         }
        //         else {
        //             //Fail
        //             console.log('renderSignedInPages - Error', items)
        //             throw new Error(`Autentieringsfel.`); //Bubble error handling
        //         }
        //     }
        //     catch (error) {
        //         console.log('renderSignedInPages - submissions Error', error);
        //         alert('Unhandled error on SUBMISSIONS')
      
        //         return false;           
        //     }
        // }

        const getSignatures = async () => {
            try {
                console.log('renderSignedInPages - Fetching signatures');
                const accessToken2 = await authX.getTokens();
                const userAttributes = await authX.getUserAttributes();
                
                const response = await Signatures({
                    accessToken:accessToken2,
                    sub:userAttributes.sub,
                })
                console.log('renderSignedInPages - signatures', response);

                //LOOP HERE UNTIL WE HAVE ALL... Maybe... or in apiconnect


    
                if(response) {
                    //Success
                    //accountMain.replaceChildren(renderSubmissions(items));
                    return response;
                }
                else {
                    //Fail
                    console.log('renderSignedInPages - Error', response)
                    throw new Error(`Autentieringsfel.`); //Bubble error handling
                }
            }
            catch (error) {
                console.log('renderSignedInPages - submissions Error', error);
                alert('Unhandled error on SUBMISSIONS')
      
                return false;           
            }
        }

        //const accessToken = await authX.getCurrentUser();
        //console.log('renderSignedInPages - accessToken', accessToken);

        const root = document.createElement('div');
        root.id = 'accountContainer';
        root.classList.add('accountContainer');

        //Menu
        const accountMenu = document.createElement('div');
        accountMenu.id = 'accountMenu';
        accountMenu.classList.add('accountMenu');
        root.appendChild(accountMenu);

        // -- Orders
        const linkOrders = document.createElement('a');
        linkOrders.textContent = 'Orders';
        linkOrders.addEventListener('click', ( async (e) => {
            e.preventDefault();

            const referenceNode = document.getElementById('accountMain');
            let msgBox = renderMessageBox({
                message:'Loading...',
            });
            accountContainer.insertBefore(msgBox, referenceNode);

            //Empty elements
            accountMain.replaceChildren();
            removeCart();

            const orders = await getOrders();
            // if(orders) accountMain.replaceChildren(renderOrders(orders));
            // else {
            //     accountMain.replaceChildren(renderError({
            //         message: 'Unable to retrieve orders',
            //     }));
            // }

            if(accountContainer.contains(msgBox)) accountContainer.removeChild(msgBox);
            msgBox = null;
        }))
        accountMenu.appendChild(linkOrders);

        // Documents/Submissions
        const linkDocuments = document.createElement('a');
        linkDocuments.textContent = 'Documents';
        linkDocuments.addEventListener('click', ( async (e) => {
            e.preventDefault();

            const referenceNode = document.getElementById('accountMain');
            let msgBox = renderMessageBox({
                message:'Loading...',
            });
            accountContainer.insertBefore(msgBox, referenceNode);

            accountMain.replaceChildren();

            const documents = await getSubmissions();
            console.log('submission documents loaded', documents);

            if(documents) {
                const signatures = await getSignatures();

                console.log('SIGNATURES (all - fix when we have hundreds)', signatures);

                accountMain.replaceChildren(renderSubmissions(documents, signatures));

                console.log('ACCT', ACCOUNT);
                if(ACCOUNT.hasOwnProperty('cart') && ACCOUNT.cart.length > 0) renderCart();
            }
            else {
                accountMain.replaceChildren(renderError({
                    message: 'Unable to retrieve documents (submissions)',
                }));
            }

            if(accountContainer.contains(msgBox)) accountContainer.removeChild(msgBox);
            msgBox = null;
        }))
        accountMenu.appendChild(linkDocuments);

        // -- Signout
        const linkSignout = document.createElement('a');
        linkSignout.textContent = 'Sign out';
        linkSignout.addEventListener('click', (async (e) => {
            e.preventDefault();
            authX.signOut(); //Cause dispatchEvent that proceeds flow 
        }))
        accountMenu.appendChild(linkSignout);

        //Main area
        const accountMain = document.createElement('div');
        accountMain.id = 'accountMain';
        accountMain.classList.add('accountMain');
        accountMain.innerHTML = `Loading...`; //Not here the delay is...
        root.appendChild(accountMain);

        //Remove cart
        removeCart();
        
        //Get orders
        const orders = await getOrders();
        // if(orders) accountMain.replaceChildren(renderOrders(orders));
        // else {
        //     accountMain.replaceChildren(renderError({
        //         message: 'Unable to retrieve orders',
        //     }));
        // }

        return root;
    }
}

const getSubmissions = async params => {
    try {
        console.log('renderSignedInPages - Fetching submissions.... 1');
        console.log('renderSignedInPages - Fetching submissions....', params);

        const accessToken2 = await authX.getTokens();
        const userAttributes = await authX.getUserAttributes();
        
        const input = {
            accessToken:accessToken2,
            sub:userAttributes.sub,
        }

        //Add LastEvaluatedKey
        if(params !== undefined && params.hasOwnProperty('lastEvaluatedKey')) input.lastEvaluatedKey = params.lastEvaluatedKey;

        const items = await Submissions(input);
        console.log('renderSignedInPages - submissions items', items);

        if(items) {
            //Success
            //accountMain.replaceChildren(renderSubmissions(items));
            return items;
        }
        else {
            //Fail
            console.log('renderSignedInPages - Error', items)
            throw new Error(`Autentieringsfel.`); //Bubble error handling
        }
    }
    catch (error) {
        console.log('renderSignedInPages - submissions Error', error);
        alert('Unhandled error on SUBMISSIONS')

        return false;           
    }
}

export default pageAccount;