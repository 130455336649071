/*
    https://www.youtube.com/watch?v=IZGNcSuwBZs
*/


import pageMain from "./pageMain.js";
import pageAccount from "./pageAccount.js";
import './styles/main.scss';

// const GLOBALS = {
//   //appId:  config.APP_ID,
//   //lang:   config.LANG,
//   //stage:  config.STAGE,
//   //apiURL: config.API_URL,
//   foo: 'bar',
// };


// import {
//   CognitoUserPool,
//   CognitoUserAttribute,
// } from 'amazon-cognito-identity-js';


const header = document.getElementById('header');
const headerMenu = document.getElementById('headerMenu');
const container = document.getElementById('container');

//Menu items
const linkMain = document.createElement('a');
linkMain.textContent = 'Hem';
linkMain.addEventListener('click', ((e) => {
  e.preventDefault();
  container.replaceChildren(pageMain('main'));
}))
headerMenu.appendChild(linkMain);

const linkVisitor = document.createElement('a');
linkVisitor.textContent = 'Du som vill Pressa';
linkVisitor.addEventListener('click', ((e) => {
  e.preventDefault();
  container.replaceChildren(pageMain('visitor'));
}))
headerMenu.appendChild(linkVisitor);

const linkBroker = document.createElement('a');
linkBroker.textContent = 'Försäkringsmäklare';
linkBroker.addEventListener('click', ((e) => {
  e.preventDefault();
  container.replaceChildren(pageMain('broker'));
}))
headerMenu.appendChild(linkBroker);

const linkValidate = document.createElement('a');
linkValidate.textContent = 'Validera dokument';
linkValidate.addEventListener('click', ((e) => {
  e.preventDefault();
  container.replaceChildren(pageMain('validate'));
}))
headerMenu.appendChild(linkValidate);

const linkAbout = document.createElement('a');
linkAbout.textContent = 'Om oss';
linkAbout.addEventListener('click', ((e) => {
  e.preventDefault();
  container.replaceChildren(pageMain('about'));
}))
headerMenu.appendChild(linkAbout);

const linkMyPages = document.createElement('a');
linkMyPages.textContent = 'Mina Sidor';
linkMyPages.addEventListener('click', ( async (e) => {
  e.preventDefault();
  await pageAccount(container);
}))
headerMenu.appendChild(linkMyPages);

//Init
container.replaceChildren(pageMain());