import ImgHero from './assets/hero.png';
import ImgVisitor from './assets/visitors.png';
import ImgBroker from './assets/broker.png';
import ImgValidate from './assets/validate.png';
import ImgDeal from './assets/deal.png';
import ImgBankID from './assets/BankID_logo.png';

/*
<p>
    En försäkringsförmedlare har också möjlighet att skapa helt egna försäkringsprodukter
    som du vanligtvis inte kan förhandla fram när du kontaktar försäkringsbolagen direkt.
</p>
*/

const visitorHTML =  `
<h2>Hej du som vill pressa premien</h2>
<p>
    Vi hjälper försäkringsförmedlare så att de kan hjälpa dig med att pressa premien! Pressa Premien sparar
    både din och din försäkringsmäklares tid genom att effektivisera flödet av insamling av kundinformation till färdig offert till dig. 
    En försäkringsförmedlare har genom att representera flera klienter också ett större mandat
    och därmed större möjligheter att påverka premien till din fördel vid val av försäkringsbolag.
</p>
<p>
    För att kunna agera för din räkning så måste du ge försäkringsmäklaren en förmedlarfullmakt att sluta avtal
    med försäkringsbolagen. Denna fullmakt skapas, signeras med BankID, och lagras här på på Pressa Premien. 
    Förmedlaren har alltid tillgång till era ingångna avtal och försäkringsbolag kan kontakta oss för att verifiera att dokument
    de fått tillhandahållna från mäklarna är ett original som skapats på denna webbplatsen
</p>
<p>
    Oftast tar förmedlaren betalt för sitt arbete genom en provision som utgår från försäkringsbolaget per förmedlad försäkring.
    När mäklaren använder Pressa Premien får de dessutom ett effektivt verkyg där de kan minska sina kostnader som kommer dig tillgodo.
</p>
`;

const brokerHTML = `
<h2>Hej försäkringsmäklare</h2>
<p>
    Pressa Premien finns för dig som letar efter en smidig lösning för att samla in offertunderlag
    och hantera signeringen av förmedlarfullmakten på ett tryggt och säkert sätt.
</p>
<p>
    På Pressa Premien kan du skapa ett formulär med de frågor som just din försäkringsprodukt kräver. Vill du ha en egen grafisk profil
    eller landningssida dit du eller din partner kan direktlänka så sätter vi upp en unik subdomän för din verksamhet.
</p>
<p>
    Oavsett om du är närvarande eller ej så kan dina intressenter närsomhelst och varsomhelst fylla i formuläret.
    Dina ordrar kommer in snabbare och du kommer spara massor av tid.
</p>
<p>
    Arbetar du med en avtalspartner med egna säljare? Nu kan du låta dessa samla in underlagen medan du fokuserar
    på att skapa de bästa förutsättningarna för dina kunder.
</p>
<p>
    Via konto-sidan kommer du sedan åt dina ordrar med offertunderlag och signerade förmedlarfullmakter. Du har även möjlighet 
    att skriva ut de signatur-verifikat som försäkringsbolagen kräver.
</p>
<p>
    Originaldokumenten sparas alltid som PDF i Pressa Premien-databasen. Därmed går det alltid att verifiera
    ett dokuments autenticitet och att verifiera BankID-signaturen.
</p>
`;

const validateHTML = `
<h2>Verifiera signatur</h2>
<p>
    Har du fått ett dokument signerat hos oss? Här kan du snart ladda upp ditt dokument för att validera att dokumentet inte har manipulerats.
    Dokumentet skall ha samman namn som när det skapades hos oss.
</p>
<p>
    Har dokumentet bytt namn kan vi inte validera dokumentets autenticitet med denna tjänst. Be i första hand försäkringsförmedlaren att ta fram originalnamnet.
    Är detta inte möjligt så kan vi utföra en manuell kontroll mot avgift (kontakta oss).
</p>`;

const aboutHTML = `
<h2>Om oss</h2>
<p>
    Pressa Premien är en tjänst som utvecklas av oss på <a href="https://meducati.com" target="_blank">Meducati AB</a>. Meducati har som affärsidé att utveckla och erbjuda smarta lösningar 
    inom olika segment. Vi kombinerar det bästa från befintliga produkter så att vi kan utveckla nya på ett smart, effektivt och tryggt sätt.
    Efter 20+ år har vi samlat på oss en hel del erfarenheter och kan vi ta oss an det mesta.
    </p>
<p>
    Pressa Premien uppstod efter att en försäkringsmäklare kontaktat oss och bett om hjälp att utveckla en lösning 
    som på ett enkelt sätt mötte mäklarens krav på att korta ner mötestider, hantering av en stor mängd förfrågningar, upprättandet av 
    förmedlarfullmakterna, samt kunna hantera erfoderliga elektroniska signaturer.
</p>
<p>
    Vi har byggt vidare på denna lösning och kan nu erbjuda en tjänst där vi vill stödja fler mäklare som vill göra det enklare för både er själva och era kunder!
</p>
<p>
    Pressa Premien lanserades i December 2023 och vi håller fortfarande på med finputsandet. Kontakta oss gärna direkt för
    mer information och kundreferenser! 
</p>
<p>
    <br>
    Pressa Premien och Meducati hittar ni på:<br>
    <br>Stora Åvägen 21
    <br>436 34 Askim (Göteborg)
    <br>hello@meducati.com    
</p>`;

const homeHTML = `
<h2>Nu pressar vi premien!</h2>
<p>
    Här hjälper vi er försäkringsmäklare att skapa enklare och tydligare offertunderlag där era kunder med eller utan er assistans kan fylla ut
    offerformulär som anpassats för just er produkt. Därefter hjälper vi er med att skapa och signera den nödvändiga förmedlarfullmakten med vår BankID-integration. 
</p>
<p>
    Fullmakter, offertunderlag, och signaturer lagras tryggt och säkert hos oss på Pressa Premien så att ni, era kunder, och alla försäkringsbolag 
    kan känna sig lugna i att alla dokument som skapats här kan valideras och kontrolleras för autencitet.
</p>
<p>
    Ni sparar tid när era kunder kan skapa uppdrag närsomhelst och utan att ni är fysiskt på plats. Formulären kan hållas korta så att
    kunden bara behöver besvara de nödvändiga frågorna. Om ni samarbetar med organisationer som har egna säljare och ambassadörer,
    så får ni med Pressa Premien ett säljverktyg som skapar ett tydligt flöde där ni fortfarande har full kontroll.
</p>
<p>
    Era frågeformulär kan publiceras direkt på pressapremien.se där de nås via en unik token eller på en subdomän om ni t.ex ville skapa en
    specialanpassad sida för en organisation ni samarbetar med.
</p>
`;

function pageMain(page) {

    const root = document.createElement('div');
    root.id = 'mainContainer';
    root.classList.add('mainContainer');

    switch(page) {
        case 'visitor':
            const sectionVisitor = document.createElement('div');
            sectionVisitor.classList.add('maxwidth50', 'section');
            sectionVisitor.innerHTML = visitorHTML;
            root.appendChild(sectionVisitor);

            const imgVisitor = new Image();
            imgVisitor.src = ImgVisitor;
            imgVisitor.classList.add('sectionImage');
            root.appendChild(imgVisitor);
        break;

        case 'broker':
            const sectionBroker = document.createElement('div');
            sectionBroker.classList.add('maxwidth50', 'section');
            sectionBroker.innerHTML = brokerHTML;
            root.appendChild(sectionBroker);

            const imgBroker = new Image();
            imgBroker.src = ImgBroker;
            imgBroker.classList.add('sectionImage');
            root.appendChild(imgBroker);
        break;

        case 'validate':
            const sectionValidate = document.createElement('div');
            sectionValidate.classList.add('maxwidth50', 'section');
            sectionValidate.innerHTML = validateHTML;
            root.appendChild(sectionValidate);

            const imgValidate = new Image();
            imgValidate.src = ImgValidate;
            imgValidate.classList.add('sectionImage');
            root.appendChild(imgValidate);
        break;

        case 'about':
            const sectionAbout = document.createElement('div');
            sectionAbout.classList.add('maxwidth50', 'section');
            sectionAbout.innerHTML = aboutHTML;
            root.appendChild(sectionAbout);

            const imgAbout = new Image();
            imgAbout.src = ImgDeal;
            imgAbout.classList.add('sectionImage');
            root.appendChild(imgAbout);
        break;


        default:
            const imgHero = new Image();
            imgHero.src = ImgHero;
            imgHero.classList.add('sectionImage');
            root.appendChild(imgHero);

            const sectionDedault = document.createElement('div');
            sectionDedault.classList.add('maxwidth50', 'section');
            sectionDedault.innerHTML = homeHTML;
            root.appendChild(sectionDedault);

            const imgBankID = new Image();
            imgBankID.src = ImgBankID;
            imgBankID.classList.add('sectionImage', 'width12');
            root.appendChild(imgBankID);
    }
    return root;
}

export default pageMain;