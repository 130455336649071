// exports.Orders = async (params) => {
//     //console.log('Orders init', params);

//     try {
//         let id = '';
//         if(params.hasOwnProperty('id')) id = params.id;

//         //let url = new URL(`${apiURL}orders/${id}`)
//         let url = new URL(`${process.env.API_URL}orders/${id}`);
//         url.search = new URLSearchParams({
//             sub:params.sub,
//         })
//         //console.log('Orders url', url);
//         //console.log('Orders params.accessToken', params.accessToken);

//         const response = await fetch(url, {    
//             method: "GET",
//             headers: {
//                 "Authorization": `Bearer ${params.accessToken}`,
//                 "Accept": "application/json",
//             }, 
//         });
//         //console.log('Orders fetch response', response);
    
//         if (response?.ok) {
//             const result = await response.json();
//             //console.log('result', result);
//             return result;
//         } else {
//           console.log(`Orders HTTP Response Code: ${response?.status}`);
//           console.log('Orders response response not ok', response);
    
//           throw new Error(`Error retrieving order(s)`);
//         }
//     } catch (error) {
//         console.log('Orders Error', error);        
//         return false;
//     }
// }

exports.Orders = params => {
    //console.log('Orders init', params);
    return new Promise( async (resolve, reject) => {
        try {
            let id = '';
            if(params.hasOwnProperty('id')) id = params.id;
    
            //let url = new URL(`${apiURL}orders/${id}`)
            let url = new URL(`${process.env.API_URL}orders/${id}`);
            //Include filter if applicable
            if(params.hasOwnProperty('filter')) {
                url.search = new URLSearchParams({
                    filter: JSON.stringify(params.filter)
                })
            }
            //Fetch
            const response = await fetch(url, {    
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${params.accessToken}`,
                    "Accept": "application/json",
                }, 
            });
            //console.log('Orders fetch response', response);
            const resultBody = await response.json();
            console.log('resultBody', resultBody);

            if (response?.ok) {
                //const result = await response.json();
                //console.log('result', result);
                resolve(resultBody);
            } else {
              console.log(`Orders HTTP Response Code: ${response?.status}`); //400
              //const resultBody = await response.json();
              //console.log('resultBody', resultBody);
              throw new Error(`Error retrieving order(s): ${resultBody.message}`);
            }
        } catch (error) {
            console.log('Error orders', error.message);    
            reject({
                message: error.message,
            });
        }
    })   
}

exports.Signatures = async (params) => {
    console.log('Signatures init', params);

    try {
        let id = '';
        if(params.hasOwnProperty('id') && params.id !== undefined) id = encodeURIComponent(params.id); //Time here if available



        //This should work also...

        // if(params.hasOwnProperty('pk') && params.hasOwnProperty('sk')) {
        //     //id = params.id;
        //     id = new URLSearchParams(`sk=${params.sk}`);
        // }
        
        


        //else {
        //    throw new Error('Property id is required')
        //}

        console.log('id2 (time?)', id);

        let url = new URL(`${process.env.API_URL}signatures/${id}`)

        const searchParams = {
            sub:params.sub,
            //test:params.id,
            //test2:params.sk,
        }
        if(params.hasOwnProperty('signatures')) searchParams.signatures = btoa(JSON.stringify(params.signatures));

        url.search = new URLSearchParams(searchParams);
        console.log('url', url);

        const response = await fetch(url, {    
            method: "GET",
            headers: {
                "Authorization": `Bearer ${params.accessToken}`,
                "Accept": "application/pdf",
            }, 
        });
        console.log('response', response);
    
        if (response?.ok) {
            if(id !== '') {
                const result = await response.blob();
                //console.log('Signatures - result', result);

                //https://docs.aws.amazon.com/apigateway/latest/developerguide/how-to-cors.html
                const header = response.headers.get('Content-Disposition');
                
                //https://medium.com/@nerdyman/prompt-a-file-download-with-the-content-disposition-header-using-fetch-and-filesaver-8683faf565d0

                const filename = header.split(';')
                    .find(n => n.includes('filename='))
                    .replace('filename=', '')
                    .replaceAll('"', '')
                    .trim()
                console.log('Signatures - filename', filename);

                const blob = new Blob([result], {type: 'application/pdf'})
                
                return {
                    filename: filename,
                    blob: blob,   
                }
            }
            else {
                //All signatures, no blobs
                const result = await response.json();
                console.log('result', result);
    
                return result;
            }
        } else {
            console.log(`Signatures - HTTP Response Code: ${response?.status}`);
            //console.log('response', response);

            const err = await response.json();
            console.log('err', err);
    
            throw new Error(`Error retrieving signature(s)`);
        }
    } catch (error) {
        console.log('Signatures - Error', error)
        return false;
    }
}

exports.Files = async (params) => {
    //console.log('Files - init', params);

    try {
        let id = '';
        if(params.hasOwnProperty('id')) id = params.id;

        let url = new URL(`${process.env.API_URL}files/${id}`)
        url.search = new URLSearchParams({
            sub:params.sub,
        })
        //console.log('url', url);

        const response = await fetch(url, {    
            method: "GET",
            headers: {
                "Authorization": `Bearer ${params.accessToken}`,
                "Accept": "application/json",
            }, 
        });
        //console.log('response', response);
    
        if (response?.ok) {
            const result = await response.json();
            //console.log('result', result);
            return result;
        }
        else {
            //console.log(`HTTP Response Code: ${response?.status}`);
            const err = await response.json();
        
            if(err.hasOwnProperty('message')) throw new Error(err.message);
            else throw new Error(`Error retrieving file(s)`);
            //throw new Error(err.message);
        }
    } catch (error) {
        console.log('Files -  Error', error);
        return false;
    }
}

exports.Files2 = async (params) => {
    console.log('Files2 - init', params);

    try {
        let id = '';
        let accept;

        if(params.hasOwnProperty('id')) id = params.id;

        
        const urlParams = {
            sub:params.sub,
        }

        switch(params.method) {
            case 'PUT':
                //urlParams.pk = params.pk;
                //urlParams.sk = params.sk;
                urlParams.method = 'generate';

                accept = 'application/pdf';
            break;

            default:
                accept = 'application/json';
        }

        let url = new URL(`${process.env.API_URL}files/${id}`);
        url.search = new URLSearchParams(urlParams);
        console.log('url', url);

        const response = await fetch(url, {    
            method: params.method,
            headers: {
                "Authorization": `Bearer ${params.accessToken}`,
                "Accept": accept,
            }, 
        });
        console.log('response', response);
    
        if (response?.ok) {

            if(accept === 'application/pdf') {
                const result = await response.blob();
                console.log('Signatures - result', result);

                //https://docs.aws.amazon.com/apigateway/latest/developerguide/how-to-cors.html
                const header = response.headers.get('Content-Disposition');
                
                //https://medium.com/@nerdyman/prompt-a-file-download-with-the-content-disposition-header-using-fetch-and-filesaver-8683faf565d0

                const filename = header.split(';')
                    .find(n => n.includes('filename='))
                    .replace('filename=', '')
                    .replaceAll('"', '')
                    .trim()
                console.log('Signatures - filename', filename);

                const blob = new Blob([result], {type: 'application/pdf'})
                
                return {
                    filename: filename,
                    blob: blob,   
                }



            }
            else {
                //json default
                const result = await response.json();
                console.log('result', result);
                return result;
            }

            


            
            
        }
        else {
            //console.log(`HTTP Response Code: ${response?.status}`);
            const err = await response.json();
        
            if(err.hasOwnProperty('message')) throw new Error(err.message);
            else throw new Error(`Error retrieving file(s)`);
            //throw new Error(err.message);
        }
    } catch (error) {
        console.log('Files -  Error', error);
        return false;
    }
}

exports.Submissions = async (params) => {
    console.log('Submissions init', params);

//    const key = new URLSearchParams(`pk=${item.pk}&sk=${item.sk}`);

    try {
        let id = '';
        if(params.hasOwnProperty('pk') && params.hasOwnProperty('sk')) {
            //id = params.id;
            id = new URLSearchParams(`pk=${params.pk}&sk=${params.sk}`);
        }

        const urlParams = {
            sub:params.sub,
        }

        //lastEvaluatedKey
        if(params.lastEvaluatedKey !== undefined) urlParams.lastEvaluatedKey = btoa(JSON.stringify(params.lastEvaluatedKey));      

        let url = new URL(`${process.env.API_URL}submissions/${id}`);
        // url.search = new URLSearchParams({
        //     sub:params.sub,
        // })
        url.search = new URLSearchParams(urlParams)
        console.log('Submissions url', url);
        console.log('Submissions params.accessToken', params.accessToken);

        const response = await fetch(url, {    
            method: "GET",
            headers: {
                "Authorization": `Bearer ${params.accessToken}`,
                "Accept": "application/json",
            }, 
        });
        console.log('Submissions fetch response', response);
    
        if (response?.ok) {
            const result = await response.json();
            console.log('Submissions result', result);
            return result;
        } else {
          console.log(`Submissions HTTP Response Code: ${response?.status}`);
          console.log('Submissions response response not ok', response);
    
          throw new Error(`Error retrieving order(s)`);
        }
    } catch (error) {
        console.log('Submissions Error', error);        
        return false;
    }
}

exports.AdminOrders = (params) => {
    return new Promise( async (resolve, reject) => {
    /*
        accessToken:accessToken,
        id: order.sk,
        method: 'PATCH',
        data: [{
            status:'COMPLETED',
        }],
    */
        console.log('AdminOrders init', params);

        try {
            // switch(params.method) {
            //     case 'PATCH':
            //     break;
    
            //     default:
            // }
    
            let url = new URL(`${process.env.API_URL}adminOrders/ORDER/orders/${params.id}`);
            console.log('url', url);
    
            //Filter body here. [{ status:'COMPLETED', }]
            const bodyArray = params.data;
    
            //Send request
            const response = await fetch(url, {    
                method: params.method,
                headers: {
                    "Authorization": `Bearer ${params.accessToken}`,
                    "Accept": "application/json",
                },
                body:JSON.stringify(bodyArray),
            });
            console.log('response', response);
        
            if (response?.ok) {
                const result = await response.json();
                console.log('AdminOrders result', result);
                resolve(result);
            } else {
              console.log(`AdminOrders HTTP Response Code: ${response?.status}`);
              //console.log('AdminOrders response response not ok', response);
    
              const result = await response.json();
              console.log('AdminOrders NOT OK result', result);
        
              //throw new Error(`Error retrieving AdminOrders(s) ${result.message}`, { details: 'hmmmm', cause: 'dddd' } );
              throw new Error(`Error patching order`, { cause: result.message } );
            }
        } catch (error) {
            //console.log('AdminOrders Error', error);
            //console.log('AdminOrders error.name', error.name); //"Error"
            //console.log('AdminOrders error.message', error.message); //Enum not ....
            //console.log('AdminOrders error.details', error.details); //undefined
            //console.log('AdminOrders error.cause', error.cause); //dddd
            reject({
                name: error.name,
                message: error.message,
                cause: error.cause,
            });
        }
    })
}

exports.AdminSubmissions = async (params) => {
    console.log('AdminSubmissions init', params);

//    const key = new URLSearchParams(`pk=${item.pk}&sk=${item.sk}`);

    try {
        let id = '';
        // if(params.hasOwnProperty('pk') && params.hasOwnProperty('sk')) {
        //     id = new URLSearchParams(`pk=${params.pk}&sk=${params.sk}`);
        // }

        //FIX HERE!! NOTE Cannot be the same!!!
        const items = [];
        // items.push({
        //     //pk:params.pk,
        //     sk:params.id,
        //     ref:params.ref,
        // });
        // items.push({
        //     //pk:params.pk,
        //     sk:params.id,
        //     ref:params.ref,
        // });
        for(const item of params.items) {
            items.push({
                //pk:params.pk,
                sk:item.id,
                ref:item.ref,
            });
        }

        //Settings
        const settings = [];
        for(const item of params.settings) {
            settings.push(item);
        }

        //urlParams
        const urlParams = {
            sub:params.sub,
            ttl: 2, //hours
            items: btoa(JSON.stringify(items)),
            settings:  btoa(JSON.stringify(settings)),
        }

        switch(params.method) {
            case 'GET':
                urlParams.method = 'generateJWT';

                //accept = 'application/pdf';
            break;

            default:
                //accept = 'application/json';
        }

        let url = new URL(`${process.env.API_URL}adminSubmissions/${id}`);
        url.search = new URLSearchParams(urlParams);
        console.log('url', url);

        //Send request
        const response = await fetch(url, {    
            method: params.method,
            headers: {
                "Authorization": `Bearer ${params.accessToken}`,
                "Accept": "application/json",
            }, 
        });
        console.log('response', response);



    
        if (response?.ok) {
            const result = await response.json();
            console.log('AdminSubmissions result', result);
            return result;
        } else {
          console.log(`AdminSubmissions HTTP Response Code: ${response?.status}`);
          console.log('AdminSubmissions response response not ok', response);
    
          throw new Error(`Error retrieving AdminSubmission(s)`);
        }
    } catch (error) {
        console.log('AdminSubmissions Error', error);        
        return false;
    }
}

exports.JWT = (params) => {
    console.log('JWT init', params);

    return new Promise( async (resolve, reject) => {
        
//    const key = new URLSearchParams(`pk=${item.pk}&sk=${item.sk}`);

        try {
            let id = '';
            if(params.hasOwnProperty('token')) id = params.token;
            if(!params.hasOwnProperty('method')) throw new Error('Method is required');

            const urlParams = {
                //sub:params.sub,
                //items: btoa(JSON.stringify(items)),
            }

            switch(params.method) {
                case 'GET':
                    urlParams.method = 'validateJWT';
                break;

                default:
            }

            let url = new URL(`${process.env.API_URL}submissions/${id}`);
            url.search = new URLSearchParams(urlParams);
            console.log('url', url);

            //Send request
            const response = await fetch(url, {    
                method: params.method,
                headers: {
                    'Authorization': `Bearer ${params.token}`,
                    "Accept": "application/json",
                }, 
            });
            console.log('response', response);



        
            if (response?.ok) {
                const result = await response.json();
                console.log('JWT result', result);
                
                resolve({
                    result:result,
                });
            } else {
                const result = await response.json();
                console.log('JWT result error', result);


                console.log('JWT result error', result.error);

                console.log('JWT result error', result.error.name);

                resolve({
                    error: {
                        name:result.error.name
                    }
                })







                //console.log(`JWT HTTP Response Code: ${response?.status}`);
                //console.log('JWT response response not ok', response);
                //throw new Error(result.error.name);

                //throw new Error(`Error retrieving JWT(s)`);



            }
        } catch (error) {
            console.log('JWT Error', error);        
            reject(
                {
                    error
                }
            ); 
        }
    })
}