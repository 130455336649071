/*
    https://www.maxivanov.io/aws-cognito-amplify-vs-amazon-cognito-identity-js-vs-aws-sdk/

    https://www.cognitobuilders.training/30-lab2/50-making-api-calls-with-token/


     const test = await verifyJWT( {
                        tokenUse : 'access',
                        jwt:accessToken,
                    });
                    console.log('tr', test)


                    

*/

import * as AWS from 'aws-sdk/global';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js';

//var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export default class  {
    get getname() {

        return this.nssme; //undefined
        return this.name;
    }

    get getUser() {
        return this.cognitoUser;
    }

    get getAWSCredentials() {
        console.log('x');
        console.log(AWS.config);
        return 'foo';
    }

    constructor(container, name) {
        //let self = this;
        this.parent = container;
        this.name = name;
        this.tempUser = ''; //Used to pass information on forgotPassword -> forgotPasswordSubmit
        this.state = 'signIn'; //signIn, signUp etc - NOT REALLY USED
        this.region = process.env.REGION;
        this.poolData = {
            UserPoolId: process.env.COGNITO_USER_POOL_ID, // Your user pool id here
            ClientId: process.env.COGNITO_CLIENT_ID, // Your client id here
        };
        this.userPool = new CognitoUserPool(this.poolData);
        this.cognitoUser = this.userPool.getCurrentUser();
        this.identityPoolId = process.env.COGNITO_IDENTITY_POOL_ID;
        this.loginsKey = `cognito-idp.${process.env.REGION}.amazonaws.com/${process.env.COGNITO_USER_POOL_ID}`;

        AWS.config.region = process.env.REGION;

        this.hideResponse = () => {
            const response = document.getElementById('authenticatorResponse');
            response.innerHTML = '&nbsp;';
            response.classList.add('hidden');
        }

        this.showResponse = (message) => {
            const response = document.getElementById('authenticatorResponse');
            response.innerHTML = message;
            response.classList.remove('hidden');
        }

        this.testPassword = (string) => {
            let response = '';
            if(string.length < 7) response = 'Password is too short (8 minimum)';
            return response;
        }

        this.testGivenName = (string) => {
            let response = '';
            if(string.length === 0) response = 'Given name is empty';
            return response;
        }

        this.testFamilyName = (string) => {
            let response = '';
            if(string.length === 0) response = 'Family name is empty';
            return response;
        }

        this.testEmail = (string) => {
            let response = '';
            if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(string) === false) response = 'Email format is invalid';
            return response;
        }

        this.testCode = (number) => {
            let response = '';
            if(number < 10000) response = 'Invalid code';
            return response;
        }
    }

    getUserAttributes() {
        console.log('getUserAttributes init');

        return new Promise( (resolve, reject) => {
            this.cognitoUser.getUserAttributes(function(err, result) {
                if (err) {
                    console.log('getUserAttributes error', err); //User is not authenticated 
                    reject(err);
                    return;
                }

                let obj = {};
                for (let i = 0; i < result.length; i++) {
                    obj[result[i].getName()] = result[i].getValue();
                }
                resolve(obj);
            });
        })
    }

    getTokens() {
        console.log('getTokens init');

        return new Promise( (resolve, reject) => {
            try {
                this.cognitoUser.getSession(function(err, session) {
                    if (err) {
                        reject('No session found');
                        return;
                    } 

                    resolve(session.getAccessToken().getJwtToken());
                })
            } catch (error) {
                console.log('getTokens ERROR', error);
                reject('getTokens Error');
            }
        })
    }
   
    getCurrentUser() {
        console.log('getCurrentUser init');
        //console.log('getCurrentUser this.userPool.getCurrentUser()', this.userPool.getCurrentUser());

        const _identityPoolId = this.identityPoolId;
        const loginsKey = this.loginsKey;
        const user = this.userPool.getCurrentUser();

        return new Promise(async (resolve, reject) => {
            try {
                //console.log('this.userPool.getCurrentUser()', this.userPool.getCurrentUser());
                if(!this.userPool.getCurrentUser()) {
                    resolve(false);
                    return;
                }

                // NOTE: getSession must be called to authenticate user before calling getUserAttributes
                user.getSession(function(err, session) {
                    if (err) {
                        //alert(err.message || JSON.stringify(err));
                        console.log('getCurrentUser - User NOT authenticated -> Error getting session: ', err);
                        resolve(false);
                        return;
                    }

                    //console.log('User authenticated -> accessToken: ' + session.getAccessToken().getJwtToken());

                    //We can verify ID token here??? (But why?)
                    
                    //Represents credentials retrieved from STS Web Identity Federation using the Amazon Cognito Identity service
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: _identityPoolId,
                        Logins: {
                            [loginsKey]: session.getIdToken().getJwtToken(),
                        },
                    });

                    user.getUserAttributes(function(err, attributes) {
                        if (err) {
                            // Handle error
                            console.log('getCurrentUser - Error getting userAttributes -> error: ', err);
                            resolve(false);
                            return;
                        } else {
                            resolve(attributes);
                        }
                    });
        
                    // Instantiate aws sdk service objects now that the credentials have been updated.
                    // example: var s3 = new AWS.S3();
                });
            } catch (error) {
                console.log('getCurrentUser Error: ', error);
                reject('getCurrentUser Error');
            }
        })
    }

    async checkUser_notused() {
        console.log('checkUser - init -> NOT USED???: ', this.region);
        
        const loginsKey = this.loginsKey;
        const _identityPoolId = this.identityPoolId;

        var cognitoUser = this.userPool.getCurrentUser();

        cognitoUser.getSession(function(err, session) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            //console.log('session validity: ' + session.isValid());
            
            // NOTE: getSession must be called to authenticate user before calling getUserAttributes
            cognitoUser.getUserAttributes(function(err, attributes) {
                if (err) {
                    // Handle error
                    console.log('checkUser - Handle error: ', err);
                } else {
                    // Do something with attributes
                    console.log('checkUser - Do something with attributes: ', cognitoUser);
                }
            });

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: _identityPoolId,
                Logins: {
                    [loginsKey]: session.getIdToken().getJwtToken(),
                },
            });
            // Instantiate aws sdk service objects now that the credentials have been updated.
            // example: var s3 = new AWS.S3();
        });
    }

    signOut() {
        console.log('signOut - init');

        this.cognitoUser.signOut();

        //Notify pageAccount
        let event = new CustomEvent("authEvent", {
            detail: {
                name:'signedOut',
            },
        });
        this.parent.dispatchEvent(event);              

        return true;
    }

    async signIn(username, password) {
        const _identityPoolId = this.identityPoolId;
        const loginsKey = this.loginsKey;

        return new Promise( async (resolve, reject) => {
            //console.log('signIn - use case 4', this.userPool);

            const pool = this.userPool;

            var authenticationData = {
                Username: username,
                Password: password,
            };
    
            var authenticationDetails = new AuthenticationDetails(
                authenticationData
            );
    
            var userData = {
                Username: username,
                Pool: this.userPool,
            };
    
            //this.cognitoUser = new CognitoUser(userData); //Why new if exists? //SOME FIX HERE!!

            if(!this.cognitoUser) this.cognitoUser = new CognitoUser(userData);
            else {
                console.log('SignIn - User already exists -> ', this.cognitoUser);
            }

            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function(result) {
                    //console.log('signIn - AUTHENTICATION RESULT', result); //session

                    var accessToken = result.getAccessToken().getJwtToken();                    
                    const user = pool.getCurrentUser();

                    //https://stackoverflow.com/questions/44043289/aws-invalid-identity-pool-configuration-check-assigned-iam-roles-for-this-poo

                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: _identityPoolId,
                        Logins: {
                            [loginsKey]: result.getIdToken().getJwtToken(),
                        }
                    });
            
                    //console.log('signIn - AWS.config.credentials AFTER', AWS.config.credentials);
                    //console.log('NEEDS REFRESH??', AWS.config.credentials.needsRefresh());

                    //IF self.cognitoUser is undefined???

                    if(AWS.config.credentials.needsRefresh()) {
                        user.refreshSession(result.getRefreshToken(), (err, session) => {
                            if (err) {
                                console.log(err);
                            } else {
                                AWS.config.credentials.params.Logins[loginsKey] = session.getIdToken().getJwtToken();
                                AWS.config.credentials.refresh(err => {
                                    if (err) {
                                        console.log('signIn - ERR', err);
                                        resolve(false);
                                    } else {
                                        //console.log('signIn - TOKEN SUCCESSFULLY UPDATED');
                                        resolve(accessToken);
                                    }
                                });
                            }
                        });
                    }

                    //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                    /*
                     --> Returns credentials for the provided identity ID. Any provided logins will be validated against
                     supported login providers.
                     If the token is for cognito-identity.amazonaws.com, it will be passed through to AWS Security Token Service
                     with the appropriate role for the token

                     InvalidIdentityPoolConfigurationException

                        If you provided authentication information in the request,
                        the identity pool has no authenticated role configured,
                        or AWS STS returned an error response to the request to assume the authenticated
                        role from the identity pool.
                        If you provided no authentication information in the request,
                        the identity pool has no unauthenticated role configured,
                        or AWS STS returned an error response to the request to assume
                        the unauthenticated role from the identity pool.

                        Your role trust policy must grant AssumeRoleWithWebIdentity permissions
                        to cognito-identity.amazonaws.com.

                        HTTP Status Code: 400
                    
                    */
                    // AWS.config.credentials.refresh(error => {
                    //     if (error) {
                    //         console.error(error);

                    //         console.log('signIn - 3', error);

                    //         resolve(false);

                    //     } else {
                    //         // Instantiate aws sdk service objects now that the credentials have been updated.
                    //         // example: var s3 = new AWS.S3();
                    //         console.log('Successfully logged!');
    
    
                    //         resolve(accessToken);
    
                    //     }
                    // });
                },
            
                onFailure: function(err) {
                    console.log('signIn - authenticateUser Error', err);
                    //console.log(err.code);
                    //console.log(err.message);

                    let message;

                    switch(err.code) {
                        case 'InvalidParameterException':
                            console.log('InvalidParameterException -> ', err.message);
                            message = 'Missing parameters';
                        break;

                        case 'PasswordResetRequiredException':
                            console.log('PasswordResetRequiredException -> ', err.message);
                            message = 'Error (2)';
                        break;

                        case 'NotAuthorizedException':
                            console.log('NotAuthorizedException -> ', err.message);
                            message = 'Not authorized';
                        break;

                        case 'UserNotFoundException':
                            console.log('UserNotFoundException -> ', err.message);
                            message = 'Unknown user';
                        break;

                        default:
                            console.log('authenticateUser CODE unhandled', `${err.code} - ${err.message}`);
                            message = 'Error (1)';
                    }

                    reject(message)



                    // switch(err.message) {
                    //     case 'User does not exist.':
                    //         console.log('USER DOES NOT EXIST')
                    //     break;

                    //     default:
                    //         console.log('authenticateUser Error unhandled', err);
                    // }

                    // alert(err.message || JSON.stringify(err)); //FIX HERE!

                    // resolve(false);
                },
            });
        })
    }

    verifyJWT(params) {
        console.log('verifyJWT - init', params);

        let tokenUse = params.tokenUse; //or id

        return new Promise( async (resolve, reject) => {
            // Verifier that expects valid access tokens:
            const verifier = CognitoJwtVerifier.create({
                userPoolId: this.userPoolId,
                tokenUse: tokenUse,
                clientId: this.clientId,
            });

            try {
                const payload = await verifier.verify(params.jwt);
                //console.log("verifyJWT - Token is valid. Payload:", payload);
                resolve(payload);
            } catch {
                reject("verifyJWT - Token not valid!")
            }
        })
    }

    render(type) {
        //console.log('render - init', type);

        const root = document.createElement('div');

        switch(type) {
            case 'signIn':
                root.id = 'signInFormContainer';
                root.classList.add('signInFormContainer')

                let user = {
                    username: '',
                    password: '',
                }
                
                const form  = document.createElement('form');
                form.id = "signInForm";
                form.classList.add('signInForm');
                form.noValidate = true;  
                form.innerHTML = `
                    <div class='formInputSection'>
                        <label for="username">Email:</label>
                        <input type="text" name="username" autocomplete="username" value="${user.username}">
                    </div>
                    <div class='formInputSection'>    
                        <label for="pw">Lösenord:</label>
                        <input type="password" name="pw" autocomplete="current-password" value="${user.password}">
                    </div>`;
                form.addEventListener('submit', async e => {
                    e.preventDefault(); // prevent the form from submitting and return will not send us back to index.html  
                    btnSubmit.disabled = true; //Prevent dbl-click

                    console.log('render - Authenticating...');

                    //Add messageBox
                    this.parent.dispatchEvent(
                        new CustomEvent("authEvent", {
                            detail: {
                                name:'pauseMessage',
                                message: 'Authenticating...'
                            },
                        })
                    );

                    try {
                        //const isValid = form.reportValidity();
                        //console.log('isValid', isValid);

                        const formData = new FormData(form);
                        const tryUsername = formData.get("username");
                        const tryPassword = formData.get("pw");
                        const signInResponse = await this.signIn(tryUsername, tryPassword); //Returns false on error 
                        
                        //console.log('render - signInResponse:', signInResponse);

                        if(signInResponse) {
                            this.cognitoUser = this.userPool.getCurrentUser();

                            //We are signed in, notify pageAccount
                            let event = new CustomEvent("authEvent", {
                                detail: {
                                    name:'signedIn',
                                },
                            });
                            this.parent.dispatchEvent(event);
                        }
                        else {
                            console.log('render - HANDLE --- login error', signInResponse);
                            throw new Error();
                        }                    
                    } catch (error) {
                        console.log('render - formData error: ', error);

                        let message = 'Error';
                        if(typeof error !== 'undefined') message = error;

                        const elemMsg = document.createElement('div');
                        elemMsg.innerHTML = message;
                        root.appendChild(elemMsg);
                
                        setTimeout( () => { elemMsg.remove(); },3000);

                        // if(typeof error !== 'undefined') {
                        //     const elemMsg = document.createElement('div');
                        //     elemMsg.innerHTML = error;
                        //     root.appendChild(elemMsg);
                    
                        //     setTimeout( () => { elemMsg.remove(); },10000);
                        // }
                    }

                    //Remove messageBox
                    this.parent.dispatchEvent(
                        new CustomEvent("authEvent", {
                            detail: {
                                name:'pauseMessage',
                            },
                        })
                    );
                    
                    //Re-enable button
                    btnSubmit.disabled = false;
                });

                root.appendChild(form);

                const btnSubmit  = document.createElement('button');
                btnSubmit.classList.add('btnSubmit');
                btnSubmit.type = 'submit';
                btnSubmit.textContent = 'Sign In';

                root.querySelector('#signInForm').appendChild(btnSubmit);
                return root;
            break;
            
            // case 'signOut':
            //     console.log('render - signout USED ????');
            //     root.id = 'signOutFormContainer';
            //     root.classList.add('signOutFormContainer');

            //     const btnSignOut  = document.createElement('button');
            //     btnSignOut.id = 'btnSignOut';
            //     btnSignOut.classList.add('btnSignOut');
            //     btnSignOut.type = 'submit';
            //     btnSignOut.textContent = 'Sign Out';

            //     btnSignOut.addEventListener('click', async e => {
            //         e.preventDefault();
            //         btnSignOut.disabled = true;

            //         //console.log('render - SIGNOUT CLICK2', this.cognitoUser);
                    
            //         this.signOut(); //?
            //     });

            //     root.appendChild(btnSignOut);

            //     return root;
            // break;

            default:
                console.log('render - error state', state);
        }
    }
}